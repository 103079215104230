import { api } from '@bloobirds-it/utils';
import useSWR from 'swr';

import { RelatedObjectsTableProps } from './useCreateRelatedObject';

export const useGetMainObjects = (): {
  data: RelatedObjectsTableProps[];
  isLoading: boolean;
  isError: boolean;
  mutate: any;
} => {
  const { data, error, isLoading, mutate } = useSWR(
    `/utils/service/salesforce/relatedMainObjects`,
    (url: string) => api.get(url).then(data => data?.data),
  );

  return {
    data,
    isLoading,
    isError: error,
    mutate,
  };
};
