import React from 'react';

import { Button, Dropdown, Icon, IconButton, Text, useVisible } from '@bloobirds-it/flamingo-ui';
import clsx from 'clsx';
import spacetime from 'spacetime';

import { CreateTestRunDropdown } from './createTestRunDropdown';
import { usePlayground } from './playgroundPages';
import styles from './playgroundPages.module.css';
import { api } from '../../utils/api';

export function TestRuns() {
  const { testRuns, setSelectedRun, selectedRunId, insights, selectedTestSetId, mutateTestRuns } = usePlayground();

  const { visible, setVisible, ref } = useVisible(false);

  const handleAddTestRun = () => {
    setVisible(true);
  };

  return (
    <div className={styles.testRuns_container}>
      <div className={styles.test_header}>
        <div>
          <Icon name="repeat" size={20} color="softPeanut" />
          <Text size="l" color="softPeanut" weight="bold">
            Test runs
          </Text>
        </div>
        <Dropdown
          width={424}
          ref={ref}
          visible={visible}
          zIndex={100}
          style={{ height: 210, padding: 0 }}
          anchor={
            <IconButton
              name="add"
              color="peanut"
              onClick={handleAddTestRun}
              disabled={!selectedTestSetId}
            />
          }
          arrow={false}
        >
          <CreateTestRunDropdown hide={() => setVisible(false)} />
        </Dropdown>
      </div>
      {testRuns && testRuns.length > 0 ? (
        <div className={styles.test_list}>
          {testRuns.map(testRun => {
            const classes = clsx(styles.testRuns_testRun, {
              [styles.active]: testRun.pk === selectedRunId,
            });
            const insight = insights.find(
              insight => insight.versionIdentifier === testRun.insight_id,
            );

            async function handleStartTest() {
              try {
                await api.post(`/copilot/playground/test-run/${testRun.pk}/start`);
                mutateTestRuns();
              } catch (error) {
                console.log('error', error);
              }
            }
            return (
              <div key={testRun.pk} className={classes} onClick={() => setSelectedRun(testRun.pk)}>
                <div className={styles.testRuns_testRun_header}>
                  <div className={styles.testRuns_testRun_insight_name}>{insight.title}</div>
                </div>
                <div className={styles.testRuns_testRun_run_date}>
                  Run {spacetime(testRun.created_at).format('nice')}
                </div>
                <div className={styles.testRuns_testRun_results}>
                  {testRun.status !== 'NOT_STARTED' ? (
                    <>
                      <span className={styles.testRuns_testRun_results_success}>
                        {(
                          (testRun.summarized_results?.success /
                            testRun.summarized_results?.total) *
                          100
                        ).toFixed(1)}
                        %
                      </span>
                      <span className={styles.testRuns_testRun_results_warning}>
                        {(
                          (testRun.summarized_results?.partial_success /
                            testRun.summarized_results?.total) *
                          100
                        ).toFixed(1)}
                        %
                      </span>
                      <span className={styles.testRuns_testRun_results_error}>
                        {(
                          ((testRun.summarized_results?.failure +
                            testRun.summarized_results?.processing_error) /
                            testRun.summarized_results?.total) *
                          100
                        ).toFixed(1)}
                        %
                      </span>
                      <span className={styles.testRuns_testRun_results_without_expected}>
                        {(
                          100 -
                          ((testRun.summarized_results?.success /
                            testRun.summarized_results?.total) *
                            100 +
                            (testRun.summarized_results?.partial_success /
                              testRun.summarized_results?.total) *
                              100 +
                            ((testRun.summarized_results?.failure +
                              testRun.summarized_results?.processing_error) /
                              testRun.summarized_results?.total) *
                              100)
                        ).toFixed(1)}
                        %
                      </span>
                    </>
                  ) : (
                    <Button
                      size="small"
                      color="peanut"
                      variant="secondary"
                      iconLeft="play"
                      onClick={handleStartTest}
                    >
                      Start test
                    </Button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={styles.test_empty}>
          <span>🏃‍♀</span>
          <Text size="m" color="peanut" weight="bold">
            No test runs yet
          </Text>
          <Text size="s" color="peanut" align="center">
            Run a test set to see how the prompts are working.
          </Text>
          <Button size="small" color="lightPurple" onClick={handleAddTestRun}>
            Run a test set
          </Button>
        </div>
      )}
    </div>
  );
}
