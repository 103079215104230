import React, { useState } from 'react';

import {
  CheckItem,
  Icon,
  IconButton,
  MultiSelect,
  Skeleton,
  Text,
} from '@bloobirds-it/flamingo-ui';
import { PicklistField, TranscriptFragment } from '@bloobirds-it/types';
import clsx from 'clsx';
import useSWR from 'swr';

import { api } from '../../utils/api';
import { usePlayground } from './playgroundPages';
import styles from './playgroundPages.module.css';

function ResultCell({ hasError, result, insightType, activityResults }) {
  const { mutateSelectedTestRun, selectedRunId, mutateTestRuns } = usePlayground();
  if (hasError) {
    return <Icon name="cross" color="tomato" />;
  }
  if (insightType === 'DECISION') {
    return result;
  }
  if (activityResults?.result_tag && activityResults?.result_tag !== 'WITHOUT_EXPECTED_RESULT') {
    return result;
  }

  function handleFeedback(feedback) {
    '/test-run/{test_run_id}/{activity_id}/feedback';
    api
      .patch(
        `/copilot/playground/test-run/${selectedRunId}/${activityResults?.activity_id}/feedback`,
        {
          feedback,
        },
      )
      .then(response => {
        mutateSelectedTestRun(response, {
          opts: {
            optimisticData: response,
          },
        });
        mutateTestRuns();
      });
  }

  return (
    <div className={styles.testRun_activities_resultFeedback}>
      <IconButton
        name="thumbsDown"
        size={16}
        color="peanut"
        onClick={() => handleFeedback('FAILURE')}
      />
      <IconButton
        name="thumbsUp"
        size={16}
        color="peanut"
        onClick={() => handleFeedback('SUCCESS')}
      />
    </div>
  );
}

function ActivityRow({ activityId }) {
  const { selectedRun, selectedInsight } = usePlayground();

  const [expanded, setExpanded] = useState(false);

  const { data: picklistField } = useSWR<PicklistField>(
    `/utils/picklists/${selectedInsight?.activityField}/type`,
    key => api.get<PicklistField>(key).then(res => res.data),
  );

  function generateDecisionChips(activityResults) {
    const values = picklistField?.values.map(pv => ({
      text: pv.value,
      active: activityResults?.result?.includes(pv.id),
    }));
    return values?.map(value => {
      const classes = clsx(styles.testRun_activities_resultChip, {
        [styles.notSelected]: !value.active,
      });
      return (
        <div key={value.text} className={classes}>
          {value.text}
        </div>
      );
    });
  }

  function generateExtractionChips(activityResults) {
    const allValues = selectedInsight?.searchWords.split(',').map(word => {
      const containsValue = activityResults?.result?.includes(word);
      const classes = clsx(styles.testRun_activities_resultChip, {
        [styles.notSelected]: !containsValue,
      });
      return (
        <div key={word} className={classes}>
          {word}
        </div>
      );
    });
    return allValues;
  }

  function getResult(status) {
    if (status === 'SUCCESS') {
      return <Icon name="checkDouble" color="melon" />;
    }
    if (status === 'PARTIAL_SUCCESS') {
      return <Icon name="check" color="banana" />;
    }
    if (status === 'FAILURE') {
      return <Icon name="cross" color="tomato" />;
    }
    return <Icon name="circle" color="softPeanut" />;
  }

  const activityResults = selectedRun?.results[activityId];

  const hasError = activityResults?.error || activityResults?.result_tag === 'FAILURE_PROCESSING';

  let results;
  if (selectedInsight?.insightType === 'DECISION') {
    results = generateDecisionChips(activityResults);
  } else if (selectedInsight?.insightType === 'EXTRACTION') {
    results = generateExtractionChips(activityResults);
  } else if (selectedInsight?.insightType === 'GENERATION') {
    results = activityResults?.result;
  }
  if (hasError) {
    results = 'Error processing activity: ' + activityResults?.error;
  }

  const buttonClasses = clsx(styles.testRun_activities_results_icon, {
    [styles.expanded]: expanded,
  });

  const rowClasses = clsx(styles.testRun_activities_row, {
    [styles.error]: hasError,
  });

  return (
    <>
      <tr key={activityId} className={rowClasses}>
        <td>
          <div className={buttonClasses}>
            <IconButton
              name="voicemail"
              size={20}
              color={expanded ? 'white' : 'lightPurple'}
              onClick={() => setExpanded(v => !v)}
            />
          </div>
        </td>
        <td className={styles.testRun_activities_results}>{results || 'Processing...'}</td>
        {selectedInsight?.insightType === 'DECISION' && !hasError && (
          <td>
            <div className={styles.testRun_activities_results}>
              <ExpectedValue
                activityResults={activityResults}
                selectedInsight={selectedInsight}
                picklistField={picklistField}
                testSetId={selectedRun?.test_set_id}
              />
            </div>
          </td>
        )}
        <td className={styles.testRun_activities_result_status}>
          <ResultCell
            hasError={hasError}
            result={getResult(activityResults?.result_tag)}
            insightType={selectedInsight?.insightType}
            activityResults={activityResults}
          />
        </td>
      </tr>
      {expanded && (
        <tr className={styles.testRun_activities_expanded}>
          <td className={styles.testRun_activities_expanded_summary}>
            <div className={styles.testRun_activities_expanded_label}>Summary</div>
            <div className={styles.blocks}>
              <Text size="xs">{activityResults?.summary}</Text>
            </div>
          </td>
          <td className={styles.testRun_activities_expanded_transcript}>
            <div className={styles.testRun_activities_expanded_label}>Transcript</div>
            <div className={styles.blocks}>
              {activityResults?.transcription?.map((fragment, index) => (
                <ConversationBlock key={index} fragment={fragment} />
              ))}
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

const ConversationBlock = ({ fragment }: { fragment: TranscriptFragment }) => {
  return (
    <div>
      <div className={styles.fragmentHeader}>
        <Text color="purple" size="xs" weight="bold">
          {/* @ts-ignore */}
          <Icon name={fragment.isLead ? 'person' : 'user'} color="purple" size={16} />
          {fragment.speaker} ({fragment.isLead ? 'lead' : 'user'})
        </Text>
      </div>

      <Text size="xxs" className={styles.transcription_text}>
        {fragment.text}
        <br />
      </Text>
    </div>
  );
};

function ExpectedValue({ activityResults, selectedInsight, picklistField, testSetId }) {
  const { mutateSelectedTestRun, mutateTestRuns } = usePlayground();

  const [values, setValues] = useState(() => {
    if (activityResults?.expected_result?.result) {
      return activityResults?.expected_result?.result;
    }
    return [];
  });

  function handleValueChange(value) {
    const currentValue = values;
    setValues(value);
    api
      .post(`copilot/playground/test-set/${testSetId}/expected-result`, {
        activityId: activityResults?.activity_id,
        result: value,
        insightId: selectedInsight?.versionIdentifier,
      })
      .then(() => {
        mutateSelectedTestRun();
        mutateTestRuns();
      })
      .catch(() => {
        setValues(currentValue);
      });
  }

  return (
    <MultiSelect size="small" value={values} onChange={handleValueChange}>
      {picklistField?.values.map(value => (
        <CheckItem key={value.id} value={value.id}>
          {value.value}
        </CheckItem>
      ))}
    </MultiSelect>
  );
}

export function ActivityTab() {
  const { selectedRun, insights, selectedInsight } = usePlayground();

  useSWR<PicklistField>(`/utils/picklists/${selectedInsight?.activityField}/type`, key =>
    api.get<PicklistField>(key).then(res => res.data),
  );

  const insightType = selectedInsight?.insightType;

  return (
    <table className={styles.testRun_activities}>
      <thead>
        <tr>
          <th>Activity</th>
          <th className={styles.testRun_activities_results}>Suggested value</th>
          {insightType === 'DECISION' && <th>Expected value</th>}
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {selectedRun?.results
          ? Object.keys(selectedRun?.results).map(activityId => {
              return <ActivityRow key={activityId} activityId={activityId} />;
            })
          : Array.from({ length: 10 }).map((_, index) => (
              <Skeleton height={18} width="100%" key={index} />
            ))}
      </tbody>
    </table>
  );
}
