// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".insights-module__insights___jUmK4 {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  padding: 16px;\n  align-self: flex-start;\n  justify-content: center;\n  margin-bottom: 12px;\n}\n\n.insights-module__insightsTitle___6ITQ8 {\n  display: flex;\n  align-items: center;\n  margin-bottom: 4px;\n  justify-content: space-between;\n}\n\n.insights-module__titleSection___fxg2o {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n}\n\n.insights-module__noResults___PPfba {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  margin: 48px;\n  gap: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/aiAnalysisPages/rightSideAiAnalysis/insights/insights.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,SAAS;AACX","sourcesContent":[".insights {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  padding: 16px;\n  align-self: flex-start;\n  justify-content: center;\n  margin-bottom: 12px;\n}\n\n.insightsTitle {\n  display: flex;\n  align-items: center;\n  margin-bottom: 4px;\n  justify-content: space-between;\n}\n\n.titleSection {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n}\n\n.noResults {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  margin: 48px;\n  gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"insights": "insights-module__insights___jUmK4",
	"insightsTitle": "insights-module__insightsTitle___6ITQ8",
	"titleSection": "insights-module__titleSection___fxg2o",
	"noResults": "insights-module__noResults___PPfba"
};
export default ___CSS_LOADER_EXPORT___;
