export const isElementLoaded = async selector => {
  let counter = 0;
  while (document.querySelector(selector) === null && counter <= 200) {
    await new Promise(resolve => {
      requestAnimationFrame(resolve);
    });
    counter = counter + 1;
  }
  return document.querySelector(selector);
};

export const waitForElement = (querySelector, resolve, reject, timeout) => {
  let timer;
  const element = document.querySelector(querySelector);
  if (element) resolve(element);
  const observer = new MutationObserver(() => {
    const element = document.querySelector(querySelector);
    if (element) {
      if (timer) clearTimeout(timer);
      resolve(element);
    }
  });
  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });
  if (timeout)
    timer = setTimeout(() => {
      console.log('Timeout reached');
      observer.disconnect();
      reject?.();
    }, timeout);
};
