import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Text, Select, Item, IconButton } from '@bloobirds-it/flamingo-ui';
import clsx from 'clsx';

import styles from './playbookPagination.module.css';

export interface PaginationProps {
  children?: any;
  rowsPerPageOptions?: number[];
  page: number;
  rowsPerPage: number;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
  hasNextPage: boolean;
}

export const PlaybookPagination = (props: PaginationProps) => {
  const {
    rowsPerPageOptions,
    page,
    hasNextPage,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
  } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'playbook.pagination' });

  const prevPageDisabled = page < 1;
  const nextPageDisabled = !hasNextPage;
  const from = page * rowsPerPage + 1;
  const to = (page + 1) * rowsPerPage;

  return (
    <div className={styles.container}>
      <Text weight="medium" size="xs" color="softPeanut">
        {t('title')}:
      </Text>
      <div className={styles.select}>
        <Select
          size="small"
          value={rowsPerPage}
          width="100%"
          variant="filters"
          onChange={rowNumber => {
            onChangePage(Math.floor((page * rowsPerPage) / rowNumber));
            onChangeRowsPerPage(rowNumber);
          }}
        >
          {rowsPerPageOptions.map((rowNumber, idx) => (
            <Item key={idx} value={rowNumber}>
              {rowNumber.toString()}
            </Item>
          ))}
        </Select>
      </div>
      <div className={styles.text}>
        <Text weight="medium" size="xs" color="softPeanut">
          {`${from} - ${to}`}
        </Text>
      </div>
      <div className={styles.buttonContainer}>
        <IconButton
          name="chevronLeft"
          size={14}
          color="peanut"
          onClick={() => {
            onChangePage(page > 0 ? page - 1 : page);
          }}
          className={clsx({ [styles.buttonHover]: !prevPageDisabled })}
          disabled={prevPageDisabled}
        />

        <IconButton
          name="chevronRight"
          size={14}
          color="peanut"
          onClick={() => onChangePage(page + 1)}
          className={clsx({ [styles.buttonHover]: !nextPageDisabled })}
          disabled={nextPageDisabled}
        />
      </div>
    </div>
  );
};
