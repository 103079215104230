import styles from './playgroundPages.module.css';
import { Button, Icon, IconButton, Text } from '@bloobirds-it/flamingo-ui';
import clsx from 'clsx';
import React from 'react';
import { usePlayground } from './playgroundPages';

export function TestSets() {
  const { setSelectedTestSet, testSets, selectedTestSetId } = usePlayground();

  const handleAddTestSet = () => {
    console.log('Add test set');
  };

  return (
    <div className={styles.testSets_container}>
      <div className={styles.test_header}>
        <div>
          <Icon name='test' size={20} color='softPeanut' />
          <Text size='l' color='softPeanut' weight='bold'>
            Test sets
          </Text>
        </div>
        <IconButton name='add' color='peanut' onClick={handleAddTestSet} />
      </div>
      {testSets && testSets.length > 0 ? (
        <div className={styles.test_list}>
          {testSets.map(testSet => {
            const classNames = clsx(styles.testSets_testSet, {
              [styles.active]: testSet.pk === selectedTestSetId,
            });
            return (
              <div
                key={testSet.pk}
                className={classNames}
                onClick={() => setSelectedTestSet(testSet.pk)}
              >
                {testSet.name}
              </div>
            );
          })}
        </div>
      ) : (
        <div className={styles.test_empty}>
          <Icon name='searchNone' size={32} color='peanut' />
          <Text size='m' color='peanut' weight='bold'>
            No test sets yet
          </Text>
          <Text size='s' color='peanut' align='center'>
            Choose the activities with you want to create a set to test your prompts with.
          </Text>
          <Button size='small' color='lightPurple' onClick={handleAddTestSet}>
            Create new test set
          </Button>
        </div>
      )}
    </div>
  );
}
