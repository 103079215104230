import { BobjectId } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import useSWR from 'swr';

export const useMainNote = (mainNote: BobjectId['value'] | null) => {
  const bobjectType = mainNote?.split('/')[1] || null;
  return useSWR(mainNote && `/${bobjectType}ViewMainNote/${mainNote}`, () =>
    api.get(`/bobjects/${mainNote}/form?injectReferences=true`),
  );
};
