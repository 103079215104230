export enum OPPORTUNITY_FIELDS_LOGIC_ROLE {
  AMOUNT = 'OPPORTUNITY__AMOUNT',
  ASSIGNED_TO = 'OPPORTUNITY__ASSIGNED_TO',
  ASSIGNED_DATE = 'OPPORTUNITY__LAST_ASSIGNED_DATE',
  ATTEMPTS_LAST_DAY = 'OPPORTUNITY__ATTEMPTS_LAST_DAY',
  AUTHOR = 'OPPORTUNITY__AUTHOR',
  CADENCE_STOPPED = 'OPPORTUNITY__CADENCE_STOPPED',
  CADENCE = 'OPPORTUNITY__CADENCE',
  CADENCE_DATA = 'OPPORTUNITY__CADENCE_DATA',
  CLOSE_DATE = 'OPPORTUNITY__CLOSE_DATE',
  COMPANY = 'OPPORTUNITY__COMPANY',
  CREATION_DATETIME = 'OPPORTUNITY__CREATION_DATETIME',
  LEADS_COUNT = 'OPPORTUNITY__LEADS_COUNT',
  NAME = 'OPPORTUNITY__NAME',
  OPT_OUT = 'OPPORTUNITY__OPT_OUT',
  PRIMARY_CONTACT = 'OPPORTUNITY__LEAD_PRIMARY_CONTACT',
  START_CADENCE = 'OPPORTUNITY__START_CADENCE',
  STATUS__CLOSED_LOST = 'OPPORTUNITY__STATUS__CLOSED_LOST',
  STATUS__CLOSED_WIN_DATE = 'OPPORTUNITY__STATUS__CLOSED_WIN_DATE',
  STATUS__CLOSED_WIN = 'OPPORTUNITY__STATUS__CLOSED_WIN',
  STATUS__LAST_UPDATE = 'OPPORTUNITY__STATUS__LAST_UPDATE',
  STATUS = 'OPPORTUNITY__STATUS',
  TYPE = 'OPPORTUNITY__TYPE',
  WITHOUT_FUTURE_TASKS = 'OPPORTUNITY__WITHOUT_FUTURE_TASKS',
  UPDATE_DATETIME = 'OPPORTUNITY__UPDATE_DATETIME',
  CLOSED_LOST_REASON = 'OPPORTUNITY__CLOSED_LOST_REASON',
  EXTRA_INFO_ACTIVITY_DATE = 'OPPORTUNITY__INFO_ACTIVITY_DATE',
  EXTRA_INFO_ACTIVITY = 'OPPORTUNITY__INFO_ACTIVITY_TYPE',
  EXTRA_INFO_TASK_DATE = 'OPPORTUNITY__INFO_TASK_DATE',
  IS_INACTIVE = 'OPPORTUNITY__IS_INACTIVE',
  MAIN_NOTE = 'OPPORTUNITY__MAIN_NOTE',
  CADENCE_ENDED = 'OPPORTUNITY__CADENCE_ENDED',
  SALESFORCE_OPPORTUNITY_ID = 'SALESFORCE_OPPORTUNITY_ID',
  SALESFORCE_STATUS = 'SALESFORCE_OPPORTUNITY_STAGE',
}

export enum OPPORTUNITY_STATUS_LOGIC_ROLE {
  CLOSED_LOST = 'OPPORTUNITY__STATUS__CLOSED_LOST',
  CLOSED_WON = 'OPPORTUNITY__STATUS__CLOSED_WON',
}

export enum OPPORTUNITY_OPT_OUT_LOGIC_ROLE {
  YES = 'OPPORTUNITY__OPT_OUT__YES',
  NO = 'OPPORTUNITY__OPT_OUT__NO',
}

export enum OPPORTUNITY_LEADS_LOGIC_ROLES {
  OPPORTUNITY__LEAD_PRIMARY_CONTACT = 'OPPORTUNITY__LEAD_PRIMARY_CONTACT',
  OPPORTUNITY__LEAD_USER = 'OPPORTUNITY__LEAD_USER',
  OPPORTUNITY__LEAD_INFLUENCER = 'OPPORTUNITY__LEAD_INFLUENCER',
  OPPORTUNITY__LEAD_DECISION_MAKER = 'OPPORTUNITY__LEAD_DECISION_MAKER',
  OPPORTUNITY__LEAD_APPROVER = 'OPPORTUNITY__LEAD_APPROVER',
  OPPORTUNITY__LEAD_BUYER = 'OPPORTUNITY__LEAD_BUYER',
  OPPORTUNITY__LEAD_GATEKEEPER = 'OPPORTUNITY__LEAD_GATEKEEPER',
  OPPORTUNITY__LEAD_OTHER = 'OPPORTUNITY__LEAD_OTHER',
}

export enum OPPORTUNITY_INFO_ACTIVITY_TYPE_VALUES_LOGIC_ROLE_NAME {
  OPPORTUNITY__INFO_TYPE__OUTGOING_CALL = 'Outgoing call',
  OPPORTUNITY__INFO_TYPE__INCOMING_CALL = 'Incoming call',
  OPPORTUNITY__INFO_TYPE__MISSED_CALL = 'Missed call',
  OPPORTUNITY__INFO_TYPE__EMAIL_SENT = 'Email sent',
  OPPORTUNITY__INFO_TYPE__EMAIL_RECEIVED = 'Email received',
  OPPORTUNITY__INFO_TYPE__LINKEDIN_MESSAGE_SENT = 'LinkedIn message sent',
  OPPORTUNITY__INFO_TYPE__LINKEDIN_MESSAGE_RECEIVED = 'LinkedIn message received',
  OPPORTUNITY__INFO_TYPE__CADENCE_ENDED = 'Cadence ended',
  OPPORTUNITY__INFO_TYPE__CADENCE_STARTED = 'Cadence started',
  OPPORTUNITY__INFO_TYPE__INBOUND_ACTIVITY = 'Inbound activity',
  OPPORTUNITY__INFO_TYPE__INBOUND_LEAD = 'Inbound lead',
  OPPORTUNITY__INFO_TYPE__MEETING_CREATED = 'Meeting created',
  OPPORTUNITY__INFO_TYPE__MEETING_DONE = 'Meeting done',
  OPPORTUNITY__INFO_TYPE__NEXT_TASK = 'Tasks',
}
