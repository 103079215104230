import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, IconButton, IconType, Skeleton, Text, useHover } from '@bloobirds-it/flamingo-ui';
import { NoResultsPage } from '@bloobirds-it/misc';
import clsx from 'clsx';
import { range } from 'lodash';

import { useMessagingContentActions } from '../../../../../hooks/useMessagingContentActions';
import { Environment } from '../../../../../types/playbook';
import styles from '../../../playbookFeed.module.css';
import { PlaybookSections, TemplatesBlock } from '../../../playbookFeed.types';
import { usePlaybookFeed } from '../../../usePlaybookFeed';
import { BlankPageTooltip } from '../../blankPageTooltip/blankPageTooltip';
import { PaginationProps, PlaybookPagination } from '../../customPagination/playbookPagination';
import { noResultsContent } from '../../tabContent.utils';

const TemplateCardSkeleton = () => <Skeleton variant="rect" width="100%" height={100} />;

export const TemplateListSkeleton = ({ length = 3 }: { length?: number }) => {
  const { enviroment } = usePlaybookFeed();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        padding: enviroment === Environment.WHATSAPP_TEMPLATE_SELECTOR ? 0 : 24,
      }}
    >
      {range(length).map(number => (
        <Fragment key={number}>
          <TemplateCardSkeleton />
        </Fragment>
      ))}
    </div>
  );
};

export const AddNewButton = ({ handleClick }: { handleClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.templateAddButton} onClick={handleClick}>
      <Icon color="purple" name="plus" size={18} />
      <Text size="xs" color="purple" weight="bold">
        {t('playbook.addNew')}
      </Text>
    </div>
  );
};

export const TemplatesWrapper = ({
  emptyStates,
  paginationProps,
  templatesBlock,
  children,
}: {
  children: React.ReactNode;
  emptyStates: {
    noTemplates: boolean;
    noResults: boolean;
    isLoading: boolean;
  };
  paginationProps: PaginationProps;
  templatesBlock: TemplatesBlock;
}) => {
  const { isLoading, noTemplates, noResults } = emptyStates || {};
  const { selectedTab: tabSelected } = usePlaybookFeed();
  const { t } = useTranslation();
  const { handleAddTemplateClick } = useMessagingContentActions();

  const { actionButton, description, title } =
    noResultsContent({
      tabSelected,
      callback: handleAddTemplateClick,
      t,
    }) || {};

  return (
    <>
      {isLoading && (
        <TemplateListSkeleton
          length={
            templatesBlock === TemplatesBlock.SuggestedTemplates ? 1 : paginationProps?.rowsPerPage
          }
        />
      )}
      {noResults && !isLoading && (
        <NoResultsPage
          title={t('playbook.tabContent.noResults')}
          description={t('playbook.tabContent.noResultsHint')}
          actionButton={actionButton}
        />
      )}
      {noTemplates && !isLoading && (
        <NoResultsPage title={title} description={description} actionButton={actionButton} />
      )}
      {!noTemplates && !noResults && !isLoading && children}
      {paginationProps &&
        templatesBlock !== TemplatesBlock.SuggestedTemplates &&
        !noTemplates &&
        !noResults && (
          <div className={styles.pagination}>
            <PlaybookPagination rowsPerPageOptions={[3, 5, 10]} {...paginationProps} />
          </div>
        )}
    </>
  );
};

const playbookSections: PlaybookSections = {
  [TemplatesBlock.SuggestedTemplates]: {
    icon: 'suggestions',
    titleKey: 'playbook.tabContent.suggestedTemplates',
  },
  [TemplatesBlock.MyTemplates]: {
    icon: 'person',
    titleKey: 'playbook.tabContent.myTemplates',
  },
  [TemplatesBlock.MySnippets]: {
    icon: 'person',
    titleKey: 'playbook.tabContent.mySnippets',
  },
  [TemplatesBlock.TeamTemplates]: {
    icon: 'company',
    titleKey: 'playbook.tabContent.teamTemplates',
  },
};

export const TemplatesListHeader = ({
  templatesBlock,
  shouldShowTooltip,
  handleAddTemplateClick,
  folded,
  toggleFolded,
  showFoldButton,
}: {
  templatesBlock: TemplatesBlock;
  shouldShowTooltip: boolean;
  handleAddTemplateClick: () => void;
  folded: boolean;
  toggleFolded: () => void;
  showFoldButton?: boolean;
}) => {
  const { t } = useTranslation();
  const { sidePeekEnabled, isSmartEmail } = usePlaybookFeed();

  return (
    <div
      className={clsx(styles.templateSection, {
        [styles.smartTemplateSection]: isSmartEmail,
        [styles.templateSectionSidePeek]: sidePeekEnabled,
      })}
    >
      <Icon
        color="softPeanut"
        name={playbookSections[templatesBlock]?.icon as IconType}
        size={18}
      />
      <Text size="xs" color="softPeanut">
        {t(playbookSections[templatesBlock]?.titleKey)}
      </Text>
      {(showFoldButton || folded) && (
        <IconButton
          size={16}
          color="softPeanut"
          name={folded ? 'chevronDown' : 'chevronUp'}
          onClick={toggleFolded}
        />
      )}
      {shouldShowTooltip && <BlankPageTooltip />}
      {templatesBlock !== TemplatesBlock.SuggestedTemplates && (
        <AddNewButton handleClick={handleAddTemplateClick} />
      )}
    </div>
  );
};
