export enum MIXPANEL_EVENTS {
  SET_CADENCE_IMPORT_BULK_ACTION_CLICKED_ON_ = 'SET_CADENCE_IMPORT_BULK_ACTION_CLICKED_ON_',
  SET_CADENCE_BASIC_BULK_ACTION_CLICKED_ON_ = 'SET_CADENCE_BASIC_BULK_ACTION_CLICKED_ON_',
  STOP_CADENCE_IMPORT_BULK_ACTION_CLICKED_ON_ = 'STOP_CADENCE_IMPORT_BULK_ACTION_CLICKED_ON_',
  STOP_CADENCE_BASIC_BULK_ACTION_CLICKED_ON_ = 'STOP_CADENCE_BASIC_BULK_ACTION_CLICKED_ON_',
  REASSIGN_IMPORT_BULK_ACTION_CLICKED_ON_ = 'REASSIGN_IMPORT_BULK_ACTION_CLICKED_ON_',
  REASSIGN_BASIC_BULK_ACTION_CLICKED_ON_ = 'REASSIGN_BASIC_BULK_ACTION_CLICKED_ON_',

  //General search bar
  GLOBAL_SEARCH_BAR_RESULT_CLICKED = 'GLOBAL_SEARCH_BAR_RESULT_CLICKED',
  GLOBAL_SEARCH_BAR_QUICK_ACTION_CLICKED = 'GLOBAL_SEARCH_BAR_QUICK_ACTION_CLICKED',
  GLOBAL_SEARCH_BAR_CHANGED_TYPE_FILTER = 'GLOBAL_SEARCH_BAR_CHANGED_TYPE_FILTER',
  GLOBAL_SEARCH_BAR_SEARCHED = 'GLOBAL_SEARCH_BAR_SEARCHED',
  GLOBAL_SEARCH_BAR_OPENED = 'GLOBAL_SEARCH_BAR_OPENED',

  //Change and open bubble/sidepeek menu
  CHANGE_TO_SIDEPEEK_VIEW = 'CHANGE_TO_SIDEPEEK_VIEW',
  CHANGE_TO_BUBBLE_VIEW = 'CHANGE_TO_BUBBLE_VIEW',
  MINIMIZE_BUBBLE_MENU = 'CLOSE_BUBBLE_MENU',
  MINIMIZE_SIDEPEEK_MENU = 'CLOSE_SIDEPEEK_MENU',
  MAXIMIZE_BUBBLE_VIEW = 'OPEN_BUBBLE_MENU',
  MAXIMIZE_SIDEPEEK_VIEW = 'OPEN_SIDEPEEK_MENU',

  SKIP_TASK_FROM_CARD = 'SKIP_TASK_FROM_CARD',

  ACTIVITY_TOOLTIP_BLOCK_MARKED_AS_HIDDEN = 'ACTIVITY_TOOLTIP_BLOCK_MARKED_AS_HIDDEN',
  ACTIVITY_EMAIL_TOOLTIP_BUTTON_CLICKED = 'ACTIVITY_EMAIL_TOOLTIP_BUTTON_CLICKED',
  ACTIVITY_LINKEDIN_TOOLTIP_BUTTON_CLICKED = 'ACTIVITY_LINKEDIN_TOOLTIP_BUTTON_CLICKED',
  ACTIVITY_TOOLTIP_TOP_BUTTON_CLICKED = 'ACTIVITY_TOOLTIP_TOP_BUTTON_CLICKED',
  EXTENSION_EMAIL_ACTION_TOOLTIP_INFO_CLICK = 'EXTENSION_EMAIL_ACTION_TOOLTIP_INFO_CLICK',
  EXTENSION_EMAIL_ACTION_TOOLTIP_CLOSE_CLICK = 'EXTENSION_EMAIL_ACTION_TOOLTIP_CLOSE_CLICK',
  EXTENSION_TASK_ACTION_TOOLTIP_INFO_CLICK = 'EXTENSION_TASK_ACTION_TOOLTIP_INFO_CLICK',
  EXTENSION_TASK_ACTION_TOOLTIP_CLOSE_CLICK = 'EXTENSION_TASK_ACTION_TOOLTIP_CLOSE_CLICK',
  EXTENSION_PLAYBOOK_SNIPPETS_TOOLTIP_INFO_CLICK = 'EXTENSION_PLAYBOOK_SNIPPETS_TOOLTIP_INFO_CLICK',
  EXTENSION_PLAYBOOK_SNIPPETS_TOOLTIP_CLOSE_CLICK = 'EXTENSION_PLAYBOOK_SNIPPETS_TOOLTIP_CLOSE_CLICK',
  EXTENSION_SEE_SLOTS_TOOLTIP_CLOSE_CLICK = 'EXTENSION_SEE_SLOTS_TOOLTIP_CLOSE_CLICK',
  EXTENSION_SEE_SLOTS_TOOLTIP_INFO_CLICK = 'EXTENSION_SEE_SLOTS_TOOLTIP_INFO_CLICK',
  EXTENSION_SIMILAR_DEALS_TOOLTIP_INFO_CLICK = 'EXTENSION_SIMILAR_DEALS_TOOLTIP_INFO_CLICK',
  EXTENSION_SIMILAR_DEALS_TOOLTIP_CLOSE_CLICK = 'EXTENSION_SIMILAR_DEALS_TOOLTIP_CLOSE_CLICK',
  CALENDAR_TIPS_SEE_CLICKED = 'CALENDAR_TIPS_SEE_CLICKED',
  SNIPPETS_BUTTON_TIP_CLICKED = 'SNIPPETS_BUTTON_TIP_CLICKED',
  SNIPPETS_TOOLTIP_BLOCK_MARKED_AS_HIDDEN = 'SNIPPETS_TOOLTIP_BLOCK_MARKED_AS_HIDDEN',

  CLICK_ON_LEAD_FROM_CONTACT_VIEW_MENU_OTO = 'CLICK_ON_LEAD_FROM_CONTACT_VIEW_MENU_OTO',
  COMPLETE_TASK_FROM_CONTACT_VIEW_OTO = 'COMPLETE_TASK_FROM_CONTACT_VIEW_OTO',
  START_TASKS_FROM_TASK_TAB_OTO = 'START_TASKS_FROM_TASK_TAB_OTO',
  CLICK_ON_SEND_EMAIL_ON_EMAIL_MODAL = 'CLICK_ON_SEND_EMAIL_ON_EMAIL_MODAL',
  CLICK_ON_USE_TEMPLATE_ON_EMAIL_MODAL = 'CLICK_ON_USE_TEMPLATE_ON_EMAIL_MODAL',
  CLICK_ON_USE_SNIPPET_ON_EMAIL_MODAL = 'CLICK_ON_USE_SNIPPET_ON_EMAIL_MODAL',
  CLICK_ON_USE_TEMPLATE_ON_PLAYBOOK = 'CLICK_ON_USE_TEMPLATE_ON_PLAYBOOK',
  CLICK_ON_USE_SNIPPET_ON_PLAYBOOK = 'CLICK_ON_USE_SNIPPET_ON_PLAYBOOK',
  CLICK_ON_USE_LINKEDIN_ON_PLAYBOOK = 'CLICK_ON_USE_LINKEDIN_ON_PLAYBOOK',
  CLICK_ON_USE_WHATSAPP_ON_PLAYBOOK = 'CLICK_ON_USE_WHATSAPP_ON_PLAYBOOK',
  CLICK_ON_VIEW_PITCH_ON_PLAYBOOK = 'CLICK_ON_VIEW_PITCH_ON_PLAYBOOK',
  CLICK_ON_ADD_TASK_FROM_NEW_TASK_MODAL = 'CLICK_ON_ADD_TASK_FROM_NEW_TASK_MODAL',
  CLICK_ON_UPDATE_TASK_FROM_NEW_TASK_MODAL = 'CLICK_ON_UPDATE_TASK_FROM_NEW_TASK_MODAL',
  CLICK_ON_ADD_LEAD_OTO = 'CLICK_ON_ADD_LEAD_OTO',
  CLICK_ON_REPLY_FROM_INBOX_OTO = 'CLICK_ON_REPLY_FROM_INBOX_OTO',
  CLICK_ON_REPLY_FROM_EMAIL_OTO = 'CLICK_ON_REPLY_FROM_EMAIL_OTO',
  CLICK_IN_ACTIVITY_FROM_FEED_OTO = 'CLICK_IN_ACTIVITY_FROM_FEED_OTO',
  CLICK_ON_CALL_BUTTON_ON_DIALER_OTO = 'CLICK_ON_CALL_BUTTON_ON_DIALER_OTO',
  CLICK_ON_CALL_BUTTON_ON_AIRCALL_OTO = 'CLICK_ON_CALL_BUTTON_ON_AIRCALL_OTO',
  CLICK_ON_CALL_BUTTON_ON_NUMINTEC_OTO = 'CLICK_ON_CALL_BUTTON_ON_NUMINTEC_OTO',
  CLICK_ON_LOG_CALL_BUTTON_ON_DIALER_OTO = 'CLICK_ON_LOG_CALL_BUTTON_ON_DIALER_OTO',
  CLICK_ON_LOG_CALL_BUTTON_ON_MODAL_OTO = 'CLICK_ON_LOG_CALL_BUTTON_ON_MODAL_OTO',
  CLICK_ON_SAVE_ON_NOTE_MODAL = 'CLICK_ON_SAVE_ON_NOTE_MODAL',
  CLICK_ON_UPDATE_ON_NOTE_MODAL = 'CLICK_ON_UPDATE_ON_NOTE_MODAL',
  CLICK_ON_STAR_FROM_NOTE_MODAL = 'CLICK_ON_STAR_FROM_NOTE_MODAL',
  CLICK_ON_MAIN_NOTE_ICON_FROM_CONTACT_VIEW = 'CLICK_ON_MAIN_NOTE_ICON_FROM_CONTACT_VIEW',
  CLICK_ON_CREATE_FROM_CALENDAR_MODAL = 'CLICK_ON_CREATE_FROM_CALENDAR_MODAL',
  CLICK_ON_UPDATE_FROM_CALENDAR_MODAL = 'CLICK_ON_UPDATE_FROM_CALENDAR_MODAL',
  CLICK_ON_REPORT_MEETING_RESULT_FROM_TAB_OTO = 'CLICK_ON_REPORT_MEETING_RESULT_FROM_TAB_OTO',
  CLICK_START_CADENCE_FROM_CONTACT_VIEW_OTO = 'CLICK_START_CADENCE_FROM_CONTACT_VIEW_OTO',
  CLICK_START_CADENCE_FROM_MODAL = 'CLICK_START_CADENCE_FROM_MODAL',
  CLICK_EDIT_MEETING_RESULT_FROM_TAB_OTO = 'CLICK_EDIT_MEETING_RESULT_FROM_TAB_OTO',
  CLICK_EDIT_MEETING_TYPE_FROM_TAB_OTO = 'CLICK_EDIT_MEETING_TYPE_FROM_TAB_OTO',
  CLICK_ON_DUPLICATE_LEADS_FROM_DIALER = 'CLICK_ON_DUPLICATE_LEADS_FROM_DIALER',
  OPEN_CALENDAR_SLOTS = 'OPEN_CALENDAR_SLOTS',
  ADD_CALENDAR_SLOTS = 'ADD_CALENDAR_SLOTS',
  EDIT_CALENDAR_SLOTS = 'EDIT_CALENDAR_SLOTS',
  REMOVE_CALENDAR_SLOT = 'REMOVE_CALENDAR_SLOT',
  DELETE_CALENDAR_SLOTS = 'REMOVE_CALENDAR_SLOT',
  EXTENSION_UPDATE = 'EXTENSION_UPDATE',
  EXTENSION_UPDATE_FROM_LEFTBAR = 'EXTENSION_UPDATE_FROM_LEFTBAR',
  EDITOR_OPEN_CHANGE_SIZE = 'EDITOR_OPEN_CHANGE_SIZE',
  EDITOR_OPEN_COLOR_PICKER = 'EDITOR_OPEN_COLOR_PICKER',
  CLICK_ON_AUTOLOG_CUSTOM_ACTIVITY = 'CLICK_ON_AUTOLOG_CUSTOM_ACTIVITY',
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
  CLICK_ON_CHANGE_LANGUAGE = 'CLICK_ON_CHANGE_LANGUAGE',
  CLICK_ON_MINIMIZE_MODAL = 'CLICK_ON_MINIMIZE_MODAL',
  CLICK_ON_MAXIMIZE_MODAL = 'CLICK_ON_MAXIMIZE_MODAL',
  OPEN_WHATSAPP_FROM_EXTENSION = 'OPEN_WHATSAPP_FROM_EXTENSION',
  OPEN_WHATSAPP_FROM_WEBAPP = 'OPEN_WHATSAPP_FROM_WEBAPP',
  WHATSAPP_SYNC_CONVERSATION = ' WHATSAPP_SYNC_CONVERSATION',
  WHATSAPP_SYNC_MESSAGE = ' WHATSAPP_SYNC_MESSAGE',
  CLICK_ON_SYNC_ISSUE = 'CLICK_ON_SYNC_ISSUE',
  CLICK_ON_SEARCH_EMAIL_OPTIONS = 'CLICK_ON_SEARCH_EMAIL_OPTIONS',
  CLICK_ON_EDIT_CUSTOM_ACTIVITY = 'CLICK_ON_EDIT_CUSTOM_ACTIVITY',
  CLICK_ON_DELETE_CUSTOM_ACTIVITY = 'CLICK_ON_DELETE_CUSTOM_ACTIVITY',
  CLICK_ON_CHANGE_MEETING_RESULT = 'CLICK_ON_CHANGE_MEETING_RESULT',
  CLICK_ON_CHANGE_MEETING_TYPE_FROM_TEAM_MEETINGS_KPI = 'CLICK_ON_CHANGE_MEETING_TYPE_FROM_TEAM_MEETINGS_KPI',
  CLICK_ON_CHANGE_MEETING_RESULT_FROM_TEAM_MEETINGS_KPI = 'CLICK_ON_CHANGE_MEETING_RESULT_FROM_TEAM_MEETINGS_KPI',
  CLICK_ON_CHANGE_MEETING_TYPE_FROM_MEETINGS_IN_PERIOD_KPI = 'CLICK_ON_CHANGE_MEETING_TYPE_FROM_MEETINGS_IN_PERIOD_KPI',
  CLICK_ON_ENABLE_TEMPLATES_FROM_EDIT_STEP_CADENCE = 'CLICK_ON_ENABLE_TEMPLATES_FROM_EDIT_STEP_CADENCE',
  CLICK_ON_ADD_TEMPLATE_FROM_EDIT_STEP_CADENCE = 'CLICK_ON_ADD_TEMPLATE_FROM_EDIT_STEP_CADENCE',
  CLICK_ON_ADD_CADENCE_TAG = 'CLICK_ON_ADD_CADENCE_TAG',
  CLICK_ON_REMOVE_CADENCE_TAG = 'CLICK_ON_REMOVE_CADENCE_TAG',
  CLICK_ON_FILTER_CADENCES_BY_TAG = 'CLICK_ON_FILTER_CADENCES_BY_TAG',
  CLICK_ON_OPEN_TRANSCRIPT = 'CLICK_ON_OPEN_TRANSCRIPT',
  CLICK_ON_EDIT_TRANSCRIPT = 'CLICK_ON_EDIT_TRANSCRIPT',
  CLICK_ON_OPEN_INSIGHTS = 'CLICK_ON_OPEN_INSIGHTS',
  CLICK_ON_REGENERATE_INSIGHTS = 'CLICK_ON_REGENERATE_INSIGHTS',
  CLICK_ON_OPEN_CRM_UPDATES = 'CLICK_ON_OPEN_CRM_UPDATES',
  CLICK_ON_REGENERATE_CRM_UPDATES = 'CLICK_ON_REGENERATE_CRM_UPDATES',
  CLICK_ON_ADD_NOTE_TO_INSIGHTS = 'CLICK_ON_ADD_NOTE_TO_INSIGHTS',

  // B Links in SFDC
  CLICK_ON_SFDC_B_LINK_ = 'CLICK_ON_SFDC_B_LINK_',

  // Wizards modals buttons
  CLICK_ON_NEXT_IN_WIZARD_STEP_ = 'CLICK_ON_NEXT_IN_WIZARD_STEP_',
  CLICK_ON_SKIP_IN_WIZARD_STEP_ = 'CLICK_ON_SKIP_IN_WIZARD_STEP_',
  CLICK_ON_BACK_IN_WIZARD_STEP_ = 'CLICK_ON_BACK_IN_WIZARD_STEP_',
  CLICK_ON_CLOSE_IN_WIZARD_STEP_ = 'CLICK_ON_CLOSE_IN_WIZARD_STEP_',
  COMPLETE_TASK_FROM_CONTACT_FLOW = 'COMPLETE_TASK_FROM_CONTACT_FLOW',
  HOME_MARK_AS_DONE_ACTION_CLICKED_ON_SINGLE_CARD = 'HOME_MARK_AS_DONE_ACTION_CLICKED_ON_SINGLE_CARD',
  TASK_FEED_EDIT_TASK_ACTION_CHROME_EXTENSION = 'TASK_FEED_EDIT_TASK_ACTION_CHROME_EXTENSION',
  HOME_RESCHEDULE_ACTION_CLICKED_ON_SINGLE_CARD = 'HOME_RESCHEDULE_ACTION_CLICKED_ON_SINGLE_CARD',

  // Status note actions
  STATUS_NOTE_ACTIONS_CHANGE_STATUS = 'STATUS_NOTE_ACTIONS_CHANGE_STATUS',
  STATUS_NOTE_ACTIONS_AUTO_SAVED_NOTE = 'STATUS_NOTE_ACTIONS_AUTO_SAVED_NOTE',
  STATUS_NOTE_ACTIONS_OPEN_WHATSAPP = 'STATUS_NOTE_ACTIONS_OPEN_WHATSAPP',
  STATUS_NOTE_ACTIONS_COMPLETE_TASK = 'STATUS_NOTE_ACTIONS_COMPLETE_TASK',
  STATUS_NOTE_ACTIONS_OPEN_EMAIL = 'STATUS_NOTE_ACTIONS_OPEN_EMAIL',
  STATUS_NOTE_ACTIONS_CREATE_TASK = 'STATUS_NOTE_ACTIONS_CREATE_TASK',
  STATUS_NOTE_ACTIONS_FINISH_REPORTING = 'STATUS_NOTE_ACTIONS_FINISH_REPORTING',
  STATUS_NOTE_ACTIONS_GO_TO_MANAGE_TASKS = 'STATUS_NOTE_ACTIONS_GO_TO_MANAGE_TASKS',

  // Task management modal
  TASK_MANAGEMENT_STEP_CREATE_TASK = 'TASK_MANAGEMENT_MODAL_CREATE_TASK',
  TASK_MANAGEMENT_STEP_RESCHEDULE_TASK = 'TASK_MANAGEMENT_STEP_RESCHEDULE_TASK',
  TASK_MANAGEMENT_STEP_STOP_CADENCE = 'TASK_MANAGEMENT_MODAL_STOP_CADENCE',
  TASK_MANAGEMENT_STEP_RESCHEDULE_CADENCE = 'TASK_MANAGEMENT_STEP_RESCHEDULE_CADENCE',
  TASK_MANAGEMENT_STEP_START_CADENCE = 'TASK_MANAGEMENT_STEP_START_CADENCE',
  TASK_MANAGEMENT_STEP_MARK_TASK_AS_COMPLETED_FROM_CARD = 'TASK_MANAGEMENT_STEP_MARK_TASK_AS_COMPLETED_FROM_CARD',
  TASK_MANAGEMENT_STEP_RESCHEDULE_TASK_FROM_CARD = 'TASK_MANAGEMENT_STEP_RESCHEDULE_TASK_FROM_CARD',
  TASK_MANAGEMENT_STEP_EDIT_TASK_FROM_CARD = 'TASK_MANAGEMENT_STEP_EDIT_TASK_FROM_CARD',
  TASK_MANAGEMENT_STEP_CHANGE_TASK_PRIORITY_FROM_CARD = 'TASK_MANAGEMENT_STEP_CHANGE_TASK_PRIORITY_FROM_CARD',
  TASK_MANAGEMENT_STEP_SKIP_TASK_FROM_CARD = 'TASK_MANAGEMENT_STEP_SKIP_TASK_FROM_CARD',
}
