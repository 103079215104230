import React, { useState } from 'react';

import { Button, Icon, Text } from '@bloobirds-it/flamingo-ui';
import useSWR from 'swr';

import { api } from '../../utils/api';
import { usePlayground } from './playgroundPages';
import styles from './playgroundPages.module.css';

export function PromptTab() {
  const { selectedRunId, selectedRun, insights, selectedInsight } = usePlayground();
  const [editMode, setEditMode] = useState(false);
  const [editedPrompt, setEditedPrompt] = useState('');

  const handleEditClick = () => {
    setEditMode(true);
    setEditedPrompt(selectedInsight?.prompt);
  };

  const handleSaveClick = () => {
    // Save the edited prompt and create a new test run here
    // After saving, exit edit mode
    setEditMode(false);
  };

  const { data: insightVersion } = useSWR('api/insights/' + selectedRun?.insight_version_id, () =>
    api.get('/utils/service/copilot-insights/' + selectedRun?.insight_version_id),
  );

  return (
    <div className={styles.testRun_prompt}>
      {editMode ? (
        <div className={styles.testRun_prompt_text_edit}>
          <div className={styles.testRun_prompt_text_current}>
            <div className={styles.testRun_prompt_row_header}>
              <Icon name="eye" color="softPeanut" size={16} />
              <Text size="m" color="softPeanut" weight="bold">
                Current prompt
              </Text>
              <Text size="m" color="softPeanut">
                Version: {insightVersion?.data?.version}
              </Text>
            </div>
            <Text size="s" color="peanut" className={styles.testRun_current_prompt_text}>
              {insightVersion?.data?.prompt}
            </Text>
          </div>
          <div className={styles.testRun_prompt_text_input}>
            <div className={styles.testRun_prompt_row_header}>
              <Icon name="plus" color="softPeanut" size={16} />
              <Text size="m" color="softPeanut" weight="bold">
                New prompt
              </Text>
              <Button
                size="small"
                color="bloobirds"
                variant="secondary"
                onClick={handleSaveClick}
                iconLeft="save"
              >
                Save
              </Button>
            </div>
            <textarea
              value={editedPrompt}
              className={styles.testRun_prompt_textArea}
              onChange={e => setEditedPrompt(e.target.value)}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.testRun_prompt_header}>
            <div className={styles.testRun_prompt_row_header}>
              <Text size="m" color="softPeanut" weight="bold">
                Prompt
              </Text>
              <Text size="m" color="softPeanut">
                Version: {insightVersion?.data?.version}
              </Text>
            </div>
            {editMode ? (
              <Button size="small" color="peanut" variant="secondary" onClick={handleSaveClick}>
                Save insight and create new test run
              </Button>
            ) : (
              <Button
                size="small"
                color="peanut"
                variant="secondary"
                iconLeft="edit"
                onClick={handleEditClick}
              >
                Edit prompt
              </Button>
            )}
          </div>
          <div className={styles.testRun_prompt_text}>{insightVersion?.data?.prompt}</div>
        </div>
      )}
    </div>
  );
}
