import React, { useState } from 'react';
import { api } from '../../utils/api';
import styles from './playgroundPages.module.css';
import { Button, Item, Select, Text } from '@bloobirds-it/flamingo-ui';
import { usePlayground } from './playgroundPages';

export function CreateTestRunDropdown({ hide }) {
  const { insights, selectedTestSet, mutateTestRuns } = usePlayground();
  const [selectedInsightId, setSelectedInsightId] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  async function handleCreateTestRun() {
    try {
      setSubmitting(true);
      const response = await api.post('/copilot/playground/test-run', {
        testSetId: selectedTestSet.pk,
        insightId: selectedInsightId,
      });
      setSubmitting(false);
      mutateTestRuns();
      hide();
    } catch (error) {
      setError(error);
      setSubmitting(false);
    }
  }

  return (
    <div className={styles.createTestRunModal}>
      <div className={styles.createTestRunModal_header}>
        <Text size='m' color='peanut' weight='bold' align='center'>
          🧪 Create test run
        </Text>
      </div>
      <div className={styles.createTestRunModal_content}>
        <Text size='small' align='center'>
          You are about to create a test run for the test set{' '}
          <strong>{selectedTestSet?.name}</strong>. Select the insight you want to test.
        </Text>
        <Select
          size='small'
          value={selectedInsightId}
          onChange={setSelectedInsightId}
          placeholder='Insight to test'
          width='100%'
        >
          {insights.map(insight => (
            <Item key={insight.id} value={insight.id}>
              {insight.title}
            </Item>
          ))}
        </Select>
        <Button
          size='small'
          color={!selectedInsightId || submitting ? 'verySoftPeanut' : 'lightPurple'}
          disabled={!selectedInsightId || submitting}
          onClick={handleCreateTestRun}
        >
          {submitting ? 'Submitting' : 'Create test run'}
        </Button>
      </div>
    </div>
  );
}
