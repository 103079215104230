import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Action,
  Button,
  Callout,
  ColorType,
  Icon,
  IconButton,
  Item,
  ModalCloseIcon,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Select,
  SortableList,
  Text,
} from '@bloobirds-it/flamingo-ui';
import { relatedPickableIcons } from '@bloobirds-it/utils';

import { IconPicker, PickableIcon } from '../../../../../components/iconPicker/iconPicker';
import { RelatedField, RelatedObjectsTableProps } from '../../hooks/useCreateRelatedObject';
import EmptyFieldsList from '../../relatedObjects/emptyFieldsList';
import styles from '../mainObjects.module.css';

export const ManageObjectValuesView = ({
  crmObject,
  data,
  onClose,
  updateData,
}: {
  crmObject: any;
  data: RelatedObjectsTableProps;
  onClose: () => void;
  updateData: (newData: RelatedObjectsTableProps) => void;
}) => {
  const [error, setError] = useState({ title: false, statusField: false });
  const [values, setValues] = useState(
    data ||
      ({
        statusField: '',
        selectedFields: [],
        systemObject: false,
        display: true,
        mainRelatedObject: true,
        icon: crmObject.icon,
        title: crmObject.title,
        availableFields: crmObject.availableFields,
        objectApiName: crmObject.objectApiName,
        objectType: crmObject.objectType,
        objectTypeFieldRelated: crmObject.objectTypeFieldRelated,
      } as RelatedObjectsTableProps),
  );

  const { icon, title, statusField, selectedFields, availableFields } = values;

  const { t } = useTranslation('translation', {
    keyPrefix: 'accountSettings.relatedObjects.fieldsModal',
  });
  const { t: tMain } = useTranslation('translation', {
    keyPrefix: 'accountSettings.relatedObjects',
  });
  const [fieldsSelected, setFieldsSelected] = useState(selectedFields);

  // Delete the fields that are already selected
  const selectableFields =
    availableFields?.filter(field => !fieldsSelected?.find(f => f.apiName === field.apiName)) || [];
  const [fieldsAvailable, setFieldsAvailable] = useState(selectableFields);

  const addFieldToSelected = (value: string) => {
    const field = fieldsAvailable.find(f => f?.apiName === value);
    setFieldsSelected([...fieldsSelected, { ...field, order: fieldsSelected.length }]);

    const newFields = fieldsAvailable.filter(f => f?.apiName !== value);
    setFieldsAvailable(newFields);
  };

  const handleRemove = (value: string) => {
    const field = fieldsSelected.find(f => f?.apiName === value);
    setFieldsAvailable([...fieldsAvailable, field]);

    const newFields = fieldsSelected.filter(f => f?.apiName !== value);
    // when remove a field, we need to update the order of the rest
    newFields.forEach((field, index) => {
      field.order = index;
    });
    setFieldsSelected(newFields);
  };

  const handleReorder = (data: RelatedField[]) => {
    data.forEach((field, index) => {
      field.order = index;
    });
    setFieldsSelected(data);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!title || !statusField) {
      setError({ title: !title, statusField: !statusField });
      return;
    }

    updateData({ ...values, selectedFields: fieldsSelected });
    onClose();
  };

  const showInPreview = fieldsSelected?.slice(0, 4);
  const titleName = availableFields?.find(f => f.apiName === title)?.label;

  return (
    <>
      <ModalHeader variant="primary" size="small">
        <ModalTitle variant="primary">
          <div className={styles._title__container}>
            <Icon size={24} name="hamburgerList" color="bloobirds" />
            <Text size="m" inline color="peanut">
              {t('title')}
            </Text>
          </div>
        </ModalTitle>
        <ModalCloseIcon variant="primary" color="peanut" onClick={onClose} />
      </ModalHeader>

      <form onSubmit={handleSubmit}>
        <div className={styles._modal__content}>
          <div className={styles.mandatoryInfo}>
            <div className={styles.title}>
              <Icon size={24} name="required" color="softPeanut" />
              <Text size="m" color="softPeanut">
                {t('mandatoryInfo')}
              </Text>
            </div>
            <div className={styles.divisor} />
            <div className={styles.mandatoryInfoData}>
              <IconPicker
                selectedIcon={{
                  name: icon || 'salesforce',
                  color: relatedPickableIcons.find(p => p.name === icon ?? 'salesforce')
                    ?.color as ColorType,
                }}
                onSelectIcon={pick => {
                  setValues({
                    ...values,
                    icon: pick.name,
                  });
                }}
                hasError={false /* error.icon */}
                pickableIcons={relatedPickableIcons as PickableIcon[]}
                size={38}
              />
              <Select
                autocomplete
                width="334px"
                placeholder={tMain('titleSelectPlaceholder')}
                value={title}
                error={error.title ? tMain('fieldRequired') : undefined}
                onChange={value => {
                  setValues({
                    ...values,
                    title: value,
                  });
                  setError({ ...error, title: false });
                }}
              >
                {availableFields?.map(field => (
                  <Item key={field?.apiName} label={field?.label} value={field?.apiName}>
                    {field.label}
                  </Item>
                ))}
              </Select>
              <Select
                autocomplete
                width="334px"
                placeholder={tMain('statusFieldTitlePlaceholder')}
                value={statusField}
                error={error.statusField ? tMain('fieldRequired') : undefined}
                onChange={value => {
                  setValues({
                    ...values,
                    statusField: value,
                  });
                  setError({ ...error, statusField: false });
                }}
              >
                {availableFields?.map(field => (
                  <Item key={field?.apiName} label={field?.label} value={field?.apiName}>
                    {field.label}
                  </Item>
                ))}
              </Select>
            </div>
          </div>

          <div className={styles.extraFields}>
            <div className={styles.title}>
              <Icon name="plus" color="softPeanut" size={24} />
              <Text size="m" color="softPeanut">
                {t('extraFields')}
              </Text>
            </div>
            <div className={styles.divisor} />
            <div className={styles.extraFieldsContent}>
              <div className={styles._modal__section}>
                <Text size="s" color="softPeanut" uppercase>
                  {t('fieldsToDisplay')}
                </Text>
                <div className={styles._search_wrapper}>
                  <Select
                    placeholder={t('selectPlaceholder')}
                    width="100%"
                    autocomplete
                    onChange={value => addFieldToSelected(value)}
                    value=""
                  >
                    {fieldsAvailable?.map(field => (
                      <Item key={field?.apiName} label={field?.label} value={field?.apiName}>
                        {field?.label}
                      </Item>
                    ))}
                  </Select>
                  <div className={styles.searchInfo}>
                    <Text size="xs" color="softPeanut">
                      {t('selectHelper')}
                    </Text>
                  </div>
                </div>
                {fieldsSelected?.length > 0 ? (
                  <SortableList
                    data={fieldsSelected}
                    onReorder={handleReorder}
                    className={styles._item_list}
                    keyExtractor={field => field.apiName}
                    renderItem={({ item: field, innerRef, handleProps, containerProps }) => (
                      <div ref={innerRef} {...containerProps} className={styles._item_card}>
                        <div className={styles._item_handler} {...handleProps}>
                          <Icon name="dragAndDrop" size={16} color="softPeanut" />
                        </div>
                        <div className={styles._item_wrapper}>
                          <Text size="s" color="peanut">
                            {field?.label}
                          </Text>
                        </div>
                        <IconButton
                          name="cross"
                          color="softPeanut"
                          size={20}
                          onClick={() => handleRemove(field?.apiName)}
                        />
                      </div>
                    )}
                  />
                ) : (
                  <EmptyFieldsList />
                )}
              </div>

              <div className={styles._modal__section}>
                <Text size="s" color="softPeanut" uppercase>
                  {t('preview')}
                </Text>
                <div className={styles.previewCard}>
                  <div className={styles.previewTitle}>
                    <Action
                      icon={icon ?? 'salesforce'}
                      color={
                        relatedPickableIcons.find(p => p.name === (icon ?? 'salesforce'))
                          ?.color as ColorType
                      }
                      size="s"
                    />
                    <Text size="s" color="peanut" weight="bold">
                      {titleName}
                    </Text>
                    <div className={styles.rightSide}></div>
                  </div>
                  {showInPreview?.length > 0 && (
                    <div className={styles.previewBody}>
                      {showInPreview.map(field => (
                        <div key={field.apiName} className={styles.previewField}>
                          <Text size="xs" color="peanut">
                            {field?.label}
                          </Text>
                          <Text size="xs">-</Text>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {fieldsSelected?.length > 4 && (
                  <Callout variant="neutral" icon="info" width="100%">
                    <Text size="s" color="softPeanut" className={styles.previewHelper}>
                      {t('extraFieldsHelper')}
                    </Text>
                  </Callout>
                )}
              </div>
            </div>
          </div>
        </div>
        <ModalFooter>
          <Button onClick={onClose} variant="clear">
            {t('cancel')}
          </Button>
          <Button variant={'primary'} type="submit">
            {t('save')}
          </Button>
        </ModalFooter>
      </form>
    </>
  );
};
