import React, { useContext, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import useSWR from 'swr';

import { api } from '../../utils/api';
import styles from './playgroundPages.module.css';
import { TestSets } from './testSets';
import { TestRuns } from './testRuns';
import { TestResults } from './testResults';

type Activity = {
  [key: string]: {
    [key: string]:
      | {
          pk: string;
          account_id: string;
          activity_id: string;
          result: string;
          insight_id: string;
        }
      | {};
  };
};

type TestSet = {
  pk: string;
  account_id: string;
  name: string;
  created_at: string;
  activity_type: string;
  activities: Activity;
};

type TestSets = TestSet[];

export const PlaygroundContext = React.createContext();

export function usePlayground() {
  const context = useContext(PlaygroundContext);
  if (!context) {
    throw new Error(`usePlayground must be used within a PlaygroundProvider`);
  }
  return context;
}

export function PlaygroundProvider({ children }) {
  const { data: testSets } = useSWR<AxiosResponse<TestSets>>('/api/test-sets', () =>
    api.get('/copilot/playground/test-set'),
  );
  const [selectedTestSetId, setSelectedTestSetId] = useState(null);
  const { data: testRunsData, mutate: mutateTestRuns } = useSWR(
    selectedTestSetId ? '/api/test-runs/' + selectedTestSetId : null,
    async () => {
      const runs = await api.get(`/copilot/playground/test-set/${selectedTestSetId}/runs`);
      // Sort the runs by created_at, the most recent first
      runs.data.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      return runs;
    },
  );
  const [selectedRunId, setSelectedRunId] = useState(null);
  const { data: insights } = useSWR('api/insights', () =>
    api.get('/utils/service/copilot-insights'),
  );

  const selectedTestSet = testSets?.data.find(testSet => testSet.pk === selectedTestSetId);

  const { data: selectedRun, mutate: mutateSelectedTestRun } = useSWR(
    selectedRunId ? `/api/test-runs/${selectedRunId}` : null,
    () => api.get(`/copilot/playground/test-run/${selectedRunId}`),
  );

  useEffect(() => {
    setSelectedRunId(null);
  }, [selectedTestSetId]);

  const value = {
    testSets: testSets?.data || [],
    selectedTestSet,
    selectedTestSetId,
    setSelectedTestSet: setSelectedTestSetId,
    testRuns: testRunsData?.data || [],
    insights: insights?.data || [],
    selectedInsight: insights?.data.find(
      insight => insight.versionIdentifier === selectedRun?.data?.insight_id,
    ),
    selectedRun: selectedRun?.data,
    selectedRunId,
    setSelectedRun: setSelectedRunId,
    mutateTestRuns,
    mutateSelectedTestRun,
  };

  return <PlaygroundContext.Provider value={value}>{children}</PlaygroundContext.Provider>;
}

function PlaygroundPages() {
  return (
    <PlaygroundProvider>
      <div className={styles.playground_background}>
        <div className={styles.playground_container}>
          <TestSets />
          <TestRuns />
          <TestResults />
        </div>
      </div>
    </PlaygroundProvider>
  );
}

export default PlaygroundPages;
