import { Context, createContext, useContext } from 'react';

import { Connections, DataModelResponse, Settings, Nullable } from '@bloobirds-it/types';

export interface MeetingModalContext {
  id: string;
  accountId: string;
  userId: string;
  settings: Settings;
  connections: Connections;
  mutateConnections: () => Promise<void>;
  dataModel: DataModelResponse;
}

export type IMeetingModalContext = Nullable<MeetingModalContext>;

export const MeetingsModalContext: Context<IMeetingModalContext> = createContext<
  IMeetingModalContext
>({
  id: null,
  accountId: null,
  userId: null,
  settings: null,
  connections: null,
  mutateConnections: null,
  dataModel: null,
});
export const useMeetingsModalContext = () => useContext(MeetingsModalContext);
