import React, { useState } from 'react';

import { Modal } from '@bloobirds-it/flamingo-ui';

import { RelatedObjectsTableProps } from '../../hooks/useCreateRelatedObject';
import { ManageObjectValuesView } from './manageObjectValuesView';
import { SelectReferenceObjectView } from './selectReferenceObjectView';

export const ManageMainObjectModal = ({
  object,
  onClose,
  updateData,
}: {
  object?: any;
  onClose: () => void;
  updateData: (newData: RelatedObjectsTableProps) => void;
}) => {
  const [crmObject, setCrmObject] = useState<RelatedObjectsTableProps>();

  return (
    <Modal open onClose={onClose} variant="primary" width={830}>
      {crmObject || object ? (
        <ManageObjectValuesView
          crmObject={crmObject}
          onClose={onClose}
          data={object}
          updateData={updateData}
        />
      ) : (
        <SelectReferenceObjectView setCrmObject={setCrmObject} onClose={onClose} />
      )}
    </Modal>
  );
};
