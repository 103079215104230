import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Skeleton } from '@bloobirds-it/flamingo-ui';
import { usePreventWindowUnload } from '@bloobirds-it/hooks';

import {
  EntityCard,
  EntityCardItem,
} from '../../../../components/entityList/entityCard/entityCard';
import {
  EntityHeaderItem,
  EntityList,
  EntityListHeader,
} from '../../../../components/entityList/entityList';
import {
  AccountSettingsTab,
  AccountSettingsTabContent,
  AccountSettingsTabHeader,
  AccountSettingsTabHeaderLeft,
  AccountSettingsTabHeaderRight,
  AccountSettingsTableContainer,
  AccountSettingsTabTitle,
} from '../../../../layouts/accountSettingsLayout/accountSettingsTab/accountSettingsTab';
import { RelatedObjectsTableProps, useCreateRelatedObjects } from '../hooks/useCreateRelatedObject';
import { useGetMainObjects } from '../hooks/useGetMainObjects';
import ErrorRelated from '../relatedObjects/feedbackViews/errorRelated';
import NoRelatedFound from '../relatedObjects/feedbackViews/noRelatedFound';
import { MainObjectCard } from './mainObjectCard';
import { fieldColumns } from './mainObjects.constants';
import styles from './mainObjects.module.css';
import { ManageMainObjectModal } from './manageMainObjectModal';

export const MainBobjectSkeleton = ({ id }: { id: string }) => {
  return (
    <EntityCard key={`skeleton-${id}`} className={styles.cardRow}>
      <EntityCardItem size="small" align="center">
        <Skeleton variant="rect" height="28px" width="100px" />
      </EntityCardItem>
      <EntityCardItem align="center">
        <Skeleton variant="rect" height="28px" width="150px" />
      </EntityCardItem>
      <EntityCardItem>
        <Skeleton variant="rect" height="28px" width="200px" />
      </EntityCardItem>
      <EntityCardItem>
        <Skeleton variant="rect" height="28px" width="200px" />
      </EntityCardItem>
      <EntityCardItem>
        <Skeleton variant="rect" height="28px" width="200px" />
      </EntityCardItem>
      <EntityCardItem size="small" align="center">
        <Skeleton variant="rect" height="28px" width="150px" />
      </EntityCardItem>
    </EntityCard>
  );
};

export const MainObjectsTable = () => {
  const [openMainObjectModal, setOpenMainObjectModal] = useState(false);
  const { t } = useTranslation('translation', {
    keyPrefix: 'accountSettings.relatedObjects',
  });
  const { data, isLoading, isError, mutate } = useGetMainObjects();
  const { handleUpdate } = useCreateRelatedObjects();

  usePreventWindowUnload(isLoading);

  const updateData = async (newData: RelatedObjectsTableProps) => {
    const { data: newObject } = await handleUpdate(newData);
    const newMainObjects = [...data, newObject];
    mutate(newMainObjects, {
      optimisticData: newMainObjects,
      populateCache: true,
      revalidate: false,
    });
  };

  return (
    <>
      <AccountSettingsTab>
        <AccountSettingsTabHeader>
          <AccountSettingsTabHeaderLeft>
            <div className={styles.headerLeftcontainer}>
              <AccountSettingsTabTitle icon="list">{t('titleSelect')}</AccountSettingsTabTitle>
            </div>
          </AccountSettingsTabHeaderLeft>
          <AccountSettingsTabHeaderRight>
            <Button
              size="medium"
              variant="primary"
              uppercase={true}
              onClick={() => setOpenMainObjectModal(true)}
              disabled={isLoading || isError}
            >
              {t('createMainObject')}
            </Button>
          </AccountSettingsTabHeaderRight>
        </AccountSettingsTabHeader>
        <AccountSettingsTabContent>
          {data && data.length > 0 ? (
            <AccountSettingsTableContainer>
              <EntityList className={styles.rows}>
                <EntityListHeader>
                  {fieldColumns.map(column => (
                    <EntityHeaderItem
                      key={column?.id}
                      canBeSorted={column?.sortable}
                      align={column?.align}
                      //order={order}
                      //onClick={() => handleReorder()}
                      label={t(`${column.id}TableHeader`)}
                      icon={column.icon}
                    />
                  ))}
                </EntityListHeader>
                <>
                  {data
                    .filter((el: RelatedObjectsTableProps) => !el.systemObject)
                    .map(item => (
                      <MainObjectCard
                        key={`field-${item?.id ?? item?.objectApiName?.apiName}`}
                        data={item}
                      />
                    ))}
                </>
              </EntityList>
              <EntityList className={styles.rows}>
                {data
                  .filter(el => el.systemObject)
                  .map(item => (
                    <MainObjectCard
                      key={`field-${item?.id}`}
                      data={{ ...item, icon: 'bloobirds' }}
                    />
                  ))}
              </EntityList>
            </AccountSettingsTableContainer>
          ) : isLoading ? (
            <>
              <MainBobjectSkeleton id={'1'} />
              <MainBobjectSkeleton id={'2'} />
            </>
          ) : isError ? (
            <ErrorRelated onReload={mutate} />
          ) : (
            <NoRelatedFound onReload={mutate} />
          )}
        </AccountSettingsTabContent>
      </AccountSettingsTab>
      {openMainObjectModal && (
        <ManageMainObjectModal
          onClose={() => setOpenMainObjectModal(false)}
          updateData={updateData}
        />
      )}
    </>
  );
};
