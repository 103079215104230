import { useState } from 'react';

import { NoteDetail } from '@bloobirds-it/activity-feed';
import {
  Button,
  createToast,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalHeader,
  ModalTitle,
  Text,
} from '@bloobirds-it/flamingo-ui';
import {
  checkIsOverdue,
  useBaseSetEmailVariablesValues,
  useDataModel,
  useMainNote,
  useMinimizableModals,
  usePermissions,
  useTogglerState,
  useUserSettings,
} from '@bloobirds-it/hooks';
import {
  Bobject,
  BobjectTypes,
  COMPANY_FIELDS_LOGIC_ROLE,
  FIELDS_LOGIC_ROLE,
  LEAD_FIELDS_LOGIC_ROLE,
  MessagesEvents,
  MIXPANEL_EVENTS,
  TASK_FIELDS_LOGIC_ROLE,
  TASK_STATUS_VALUE_LOGIC_ROLE,
  UserPermission,
} from '@bloobirds-it/types';
import {
  api,
  EMAIL_MODE,
  getValueFromLogicRole,
  injectReferencesGetProcess,
  openWhatsAppWeb,
} from '@bloobirds-it/utils';
import { useWizardContext } from '@bloobirds-it/wizard-modal-context';
import mixpanel from 'mixpanel-browser';

import { useStatusNoteActionContext } from '../../hooks/useStatusNoteActions';
import { TASK_STATE } from '../../types/statusNoteActions.types';
import { ColumnHeader } from '../common/columnHeader';
import { ActionCard } from './components/actionCard/actionCard';
import styles from './quickActionColumn.module.css';

function forgeTask(icon: string, active?: boolean) {
  switch (icon) {
    case 'mail':
      return {
        icon,
        title: 'email.title',
        description: active ? 'email.hasTask' : 'email.noTask',
      };
    case 'whatsapp':
      return {
        icon,
        title: 'whatsapp.title',
        description: active ? 'whatsapp.hasTask' : 'whatsapp.noTask',
      };
    case 'taskAction':
      return {
        icon,
        title: active ? 'task.activeTitle' : 'task.completedTitle',
        description: active ? 'task.hasTask' : 'task.noTask',
      };
    case 'refresh':
      return {
        icon,
        title: 'updateCRMFields.title',
        description: 'updateCRMFields.description',
      };
    case 'noteAction':
      return {
        icon,
        title: 'generalNotes.title',
        description: 'generalNotes.description',
      };
  }
}

function Actions() {
  return (
    <div className={styles.quickActionContainer}>
      <QuickEmailCard />
      <QuickWhatsappCard />
      <QuickTaskForTodayCard />
      {/*<UpdateCRMFieldsCard />*/}
      <GeneralNotesCard />
    </div>
  );
}

export const QuickActionColumn = () => {
  const { t } = useStatusNoteActionContext();
  return (
    <>
      <ColumnHeader icon="zap" text={t('quickActionColumn.header')} />
      <Actions />
    </>
  );
};

function QuickEmailCard() {
  const { hasEmailTask } = useStatusNoteActionContext();
  const setEmailVariablesValue = useBaseSetEmailVariablesValues();
  const { openMinimizableModal } = useMinimizableModals();
  const { bobject, t, activityCompany, activityLead } = useStatusNoteActionContext();
  const defaultToLeadEmail = getValueFromLogicRole(activityLead, LEAD_FIELDS_LOGIC_ROLE.EMAIL);
  const defaultToCompanyEmail =
    getValueFromLogicRole(activityCompany, COMPANY_FIELDS_LOGIC_ROLE.EMAIL) ||
    getValueFromLogicRole(activityCompany, 'COMPANY__GENERIC_EMAIL');
  const defaultToEmail = defaultToLeadEmail || defaultToCompanyEmail;

  function handleOnClick() {
    setEmailVariablesValue({
      company: activityCompany,
      lead: activityLead ? activityLead?.raw?.contents : null,
      opportunity: bobject?.id?.typeName === BobjectTypes.Opportunity ? bobject?.rawBobject : null,
    });
    mixpanel.track(MIXPANEL_EVENTS.STATUS_NOTE_ACTIONS_OPEN_EMAIL);
    openMinimizableModal({
      type: 'email',
      title: t('newEmail'),
      data: {
        template: {
          content: '',
          subject: '',
        },
        mode: EMAIL_MODE.SEND,
        activity: null,
        company: activityCompany ?? null,
        leads: [],
        ...(activityLead && { lead: activityLead }),
        ...(defaultToEmail && {
          defaultToEmail: [defaultToEmail],
        }),
      },
      onSave: () => {
        window.dispatchEvent(
          new CustomEvent(MessagesEvents.ActiveBobjectUpdated, {
            detail: { type: BobjectTypes.Activity },
          }),
        );
      },
    });
  }

  return (
    <ActionCard
      icon="mail"
      color="tangerine"
      task={forgeTask('mail', hasEmailTask)}
      active={hasEmailTask}
      onClick={handleOnClick}
    />
  );
}

function getReferenceBobjectPhone(bobject): string {
  if (!bobject) return '';
  const bobjectType = bobject.id?.typeName;
  return (
    bobject.phoneNumbers?.[0] ||
    getValueFromLogicRole(bobject, FIELDS_LOGIC_ROLE[bobjectType]?.PHONE)
  );
}

function QuickWhatsappCard() {
  const { hasWhatsappTask, bobject, machineContext, activityLead } = useStatusNoteActionContext();
  const phone =
    getReferenceBobjectPhone(bobject) ||
    getReferenceBobjectPhone(machineContext?.referenceBobject) ||
    getValueFromLogicRole(activityLead, LEAD_FIELDS_LOGIC_ROLE.PHONE);

  const settings = useUserSettings();
  const permissions = usePermissions(settings?.user?.permissions);
  if (!permissions.get(UserPermission.SYNC_WHATSAPP_WEB_MESSAGES)) return null;

  return (
    <ActionCard
      icon="whatsapp"
      color="whatsapp"
      task={forgeTask('whatsapp', hasWhatsappTask)}
      active={hasWhatsappTask}
      onClick={() => {
        openWhatsAppWeb(true, phone);
        mixpanel.track(MIXPANEL_EVENTS.STATUS_NOTE_ACTIONS_OPEN_WHATSAPP);
      }}
    />
  );
}

function completeTask(task: Bobject<BobjectTypes.Task>, setIsLoading) {
  const taskId = task?.id?.value;
  setIsLoading(TASK_STATE.COMPLETING);
  api
    .patch(`/bobjects/${taskId}/raw`, {
      contents: {
        [TASK_FIELDS_LOGIC_ROLE.STATUS]: checkIsOverdue(task)
          ? TASK_STATUS_VALUE_LOGIC_ROLE.COMPLETED_OVERDUE
          : TASK_STATUS_VALUE_LOGIC_ROLE.COMPLETED,
      },
      params: {
        skipEmptyUpdates: true,
      },
    })
    .then(() => {
      mixpanel.track(MIXPANEL_EVENTS.STATUS_NOTE_ACTIONS_COMPLETE_TASK);
      window.dispatchEvent(
        new CustomEvent(MessagesEvents.TaskCompleted, {
          detail: { id: taskId },
        }),
      );
      window.dispatchEvent(
        new CustomEvent(MessagesEvents.ActiveBobjectUpdated, {
          detail: { type: BobjectTypes.Task },
        }),
      );
      createToast({
        message: 'Task completed',
        type: 'success',
      });
      setIsLoading(TASK_STATE.COMPLETED);
    });
}

function QuickTaskForTodayCard() {
  const { taskForToday, bobject } = useStatusNoteActionContext();
  const [taskState, setTaskState] = useState<TASK_STATE>(TASK_STATE.IDLE);
  const { openMinimizableModal } = useMinimizableModals();
  const isCompleted = taskState === TASK_STATE.COMPLETED;
  if (taskForToday) {
    return (
      <ActionCard
        icon="taskAction"
        color="bloobirds"
        task={
          taskState === TASK_STATE.COMPLETED
            ? { ...taskForToday, title: 'task.completedTitle' }
            : taskForToday
        }
        active={true}
        onClick={() => {
          if (taskState === TASK_STATE.IDLE) {
            completeTask(taskForToday as Bobject<BobjectTypes.Task>, setTaskState);
          }
        }}
        taskState={taskState}
      />
    );
  } else {
    return (
      <ActionCard
        icon="taskAction"
        color="bloobirds"
        active={true}
        task={forgeTask('taskAction', !isCompleted)}
        onClick={() => {
          if (!isCompleted) {
            mixpanel.track(MIXPANEL_EVENTS.STATUS_NOTE_ACTIONS_CREATE_TASK);
            openMinimizableModal({
              type: 'taskStatic',
              data: {
                [bobject?.id?.typeName?.toLowerCase()]: bobject,
                companyContext: undefined,
                location: 'wizard',
              },
              onSave: () => {
                setTaskState(TASK_STATE.COMPLETED);
              },
            });
          }
        }}
        taskState={taskState}
      />
    );
  }
}

function UpdateCRMFieldsCard() {
  const [open, toggle] = useTogglerState(false);
  const { t } = useStatusNoteActionContext();

  return (
    <>
      <ActionCard
        icon="refresh"
        color="bloobirds"
        task={forgeTask('refresh')}
        active={false}
        onClick={toggle}
      />
      <Modal open={open} onClose={toggle} width={340}>
        <ModalHeader color="white" size="small">
          <ModalTitle color="bloobirds" icon="refresh" size="small">
            <Text color="peanut" weight="bold" size="m">
              {t('quickActionColumn.actionCard.updateCRMFields.modalTitle')}
            </Text>
          </ModalTitle>
          <ModalCloseIcon color="bloobirds" size="small" onClick={toggle} />
        </ModalHeader>
        <ModalContent></ModalContent>

        <div className={styles['update-crm-fields-ok-button']}>
          <Button onClick={function noRefCheck() {}} size="small" uppercase={false}>
            {t('quickActionColumn.actionCard.updateCRMFields.okAction')}
          </Button>
        </div>
      </Modal>
    </>
  );
}

function GeneralNotesCard() {
  const [open, toggle] = useTogglerState(false);
  const { accountId } = useWizardContext();
  const { t, bobject } = useStatusNoteActionContext();
  let fullMainBobject: Bobject = bobject;
  const handleOnClick = async () => {
    try {
      if (!('fields' in bobject)) {
        // to identify if its a traditional bobject or an extension bobject
        const response = await api.get('/bobjects/' + bobject?.id?.value + '/form');
        fullMainBobject = response?.data;
      }
      toggle();
    } catch (error) {
      throw new Error(error);
    }
  };
  const logicRoles = FIELDS_LOGIC_ROLE[bobject?.id?.typeName];
  let mainNoteId;
  if (bobject?.mainNote) {
    mainNoteId = bobject.mainNote;
  } else {
    const mainNoteLogicRole = logicRoles?.MAIN_NOTE;
    mainNoteId = getValueFromLogicRole(bobject, mainNoteLogicRole);
  }
  const dataModel = useDataModel();

  const { data } = useMainNote(mainNoteId);

  const activityBobjectWithReferencedFields = injectReferencesGetProcess(data?.data);
  return (
    <>
      <ActionCard
        icon="noteAction"
        color="banana"
        task={forgeTask('noteAction')}
        active={false}
        onClick={handleOnClick}
      />
      {open && (
        <Modal open onClose={toggle} width={420}>
          <ModalHeader color="veryLightBloobirds" size="small">
            <ModalTitle color="peanut" icon="noteAction" size="small">
              {t('quickActionColumn.actionCard.generalNotes.modalTitle')}
            </ModalTitle>
            <ModalCloseIcon color="bloobirds" size="small" onClick={toggle} />
          </ModalHeader>

          <ModalContent className={styles.generalNotesModalContent}>
            <NoteDetail
              activity={activityBobjectWithReferencedFields}
              dataModel={dataModel}
              accountId={accountId}
              relatedBobject={fullMainBobject}
              useAsMainNote
              afterSave={toggle}
            />
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
