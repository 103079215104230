import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useConfirmDeleteModal } from '@bloobirds-it/bobjects';
import {
  Button,
  Checkbox,
  Modal,
  ModalFooter,
  Spinner,
  Tooltip,
  useToasts,
} from '@bloobirds-it/flamingo-ui';
import { useMinimizableModal, useUserSearch } from '@bloobirds-it/hooks';
import { deserialize, serialize, useRichTextEditorPlugins } from '@bloobirds-it/rich-text-editor';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
  COMPANY_FIELDS_LOGIC_ROLE,
  COMPANY_STAGE_LOGIC_ROLE,
  LEAD_FIELDS_LOGIC_ROLE,
  LEAD_STAGE_LOGIC_ROLE,
  MIXPANEL_EVENTS,
  TDateISODate,
} from '@bloobirds-it/types';
import { api, recoverScrollOfBox, removeScrollOfBox } from '@bloobirds-it/utils';
import mixpanel from 'mixpanel-browser';
import spacetime from 'spacetime';

import ActivityDetailsForm from '../components/activityDetailsForm/activityDetailsForm';
import { Calendar } from '../components/calendar/calendar';
import { MainInfoForm } from '../components/mainInfoForm/mainInfoForm';
import { NotesForm } from '../components/notesForm/notesForm';
import { useCalendar } from '../hooks/useCalendar';
import { FormDataInterface, RemindeBeforeType } from '../types/calendar';
import { stringifyArraysInObjects } from '../utils/calendar.utils';
import { MeetingModalGuests } from './components/meetingModalGuests';
import { MeetingModalHeader } from './components/meetingModalHeader';
import { MeetingModalInvitees } from './components/meetingModalInvitees';
import { IMeetingModalContext, MeetingsModalContext, useMeetingsModalContext } from './context';
import styles from './meetingModal.module.css';

const deserializeNotes = (notes: any, plugins: any[]) => {
  if (!notes) return null;
  return deserialize(notes, {
    format: 'HTML',
    plugins,
  });
};

function MeetingModalContent() {
  const {
    id,
    accountId,
    settings,
    userId,
    connections,
    mutateConnections,
    dataModel,
  } = useMeetingsModalContext();
  const { closeModal, minimize, data: formData, bobject, onSave, onClose } = useMinimizableModal<
    FormDataInterface
  >(id);
  const isEditionModal = !!bobject;
  const plugins = useRichTextEditorPlugins({
    images: false,
    replaceParagraphs: true,
  });
  const ref = useRef();
  const users = useUserSearch();
  const { t } = useTranslation('translation', { keyPrefix: 'meetingModal' });
  const {
    eventsTypeSelected,
    invitees,
    date,
    resetDate,
    calendarsAvailable,
    mutateCalendars,
    selectedTimezone,
    eventsPerDay,
    skipCalendarCreation,
    setSkipCalendarCreation,
    resetInvitees,
    setBannedEvent,
    meetingDuration,
  } = useCalendar();

  const activityTypes = dataModel?.findValuesByFieldLogicRole(ACTIVITY_FIELDS_LOGIC_ROLE.TYPE);

  const parsedFormData = {
    title: formData?.[ACTIVITY_FIELDS_LOGIC_ROLE.TITLE] || null,
    dateTime: formData?.[ACTIVITY_FIELDS_LOGIC_ROLE.TIME]
      ? new Date(formData[ACTIVITY_FIELDS_LOGIC_ROLE.TIME])
      : null,
    duration: formData?.[ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_DURATION] || meetingDuration || 60,
    calendarNotes: deserializeNotes(formData[ACTIVITY_FIELDS_LOGIC_ROLE.CALENDAR_NOTE], plugins),
    internalNotes: deserializeNotes(formData[ACTIVITY_FIELDS_LOGIC_ROLE.NOTE], plugins),
    ...formData,
    company: formData?.company?.['data'] || formData?.company,
    lead: formData?.lead?.['data'] || formData?.lead,
    opportunity: formData?.opportunity?.['data'] || formData?.opportunity,
    [ACTIVITY_FIELDS_LOGIC_ROLE.USER]: userId,
    [ACTIVITY_FIELDS_LOGIC_ROLE.TYPE]: activityTypes?.find(
      (activityType: any) => activityType?.logicRole === ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING,
    )?.id,
  };
  const methods = useForm({
    defaultValues: {
      ...parsedFormData,
    },
  });

  const { watch, control, formState, handleSubmit } = methods;

  const openCalendarPopupAfterMeeting = settings?.settings?.openCalendarPopupAfterMeeting;
  const calendarEventDecision = settings?.settings?.calendarEventDecision;
  const createAlwaysOnLinkedCalendar =
    calendarEventDecision === 'IMPERATIVE' && openCalendarPopupAfterMeeting;
  const createInCalendarCheckboxDisabled =
    calendarEventDecision === 'IMPERATIVE' || !openCalendarPopupAfterMeeting;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { isValid, submitCount } = formState;
  const canSave = submitCount === 0 || isValid;
  const { createToast } = useToasts();
  const lead = watch('lead');
  const company = watch('company');

  useEffect(() => {
    if (formData?.[ACTIVITY_FIELDS_LOGIC_ROLE.UNIQUE_NYLAS_ID]) {
      setBannedEvent(formData[ACTIVITY_FIELDS_LOGIC_ROLE.UNIQUE_NYLAS_ID]);
    }
    if (createAlwaysOnLinkedCalendar) setSkipCalendarCreation(false);
  }, []);

  function handleClose() {
    resetInvitees();
    resetDate();
    closeModal();
  }

  const onSubmit = (values: any) => {
    setIsSubmitting(true);
    const {
      company,
      lead,
      opportunity,
      duration,
      dateTime,
      title,
      calendarNotes,
      internalNotes,
      reminderTemplate,
      reminderBefore,
      reminderBeforeType,
      conferencingGoogleMeet,
      ...rest
    } = values;

    const serializeNoteText = serialize(calendarNotes, {
      format: 'AST',
      plugins,
    });

    const serializeInternalNoteText = serialize(internalNotes, {
      format: 'AST',
      plugins,
    });

    if (isEditionModal) {
      api
        .patch(`/bobjects/${bobject?.id?.value}/raw`, {
          ...stringifyArraysInObjects(rest),
          [ACTIVITY_FIELDS_LOGIC_ROLE.TIME]: dateTime,
          [ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_DURATION]: duration,
          [ACTIVITY_FIELDS_LOGIC_ROLE.COMPANY]: company?.id?.value,
          [ACTIVITY_FIELDS_LOGIC_ROLE.LEAD]: lead?.id?.value,
          [ACTIVITY_FIELDS_LOGIC_ROLE.TITLE]: title,
          [ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_INVITEES]: JSON.stringify(invitees),
          [ACTIVITY_FIELDS_LOGIC_ROLE.CALENDAR_NOTE]: serializeNoteText,
          [ACTIVITY_FIELDS_LOGIC_ROLE.NOTE]: serializeInternalNoteText,
        })
        .then(() => {
          createToast({
            type: 'success',
            message: t('toasts.updateSuccess'),
          });
          mixpanel.track(MIXPANEL_EVENTS.CLICK_ON_UPDATE_FROM_CALENDAR_MODAL);
          handleClose();
          setIsSubmitting(false);
          onSave?.();
        })
        .catch(() => {
          createToast({
            type: 'error',
            message: t('toasts.somethingHappenedWhileUpdating'),
          });
          setIsSubmitting(false);
          onSave?.();
        });
    } else {
      const reminderBeforeMultiplicator =
        reminderBeforeType === RemindeBeforeType.days
          ? 1440
          : reminderBeforeType === RemindeBeforeType.hours
          ? 60
          : 1;
      const data = {
        title,
        meetingDateTime: dateTime,
        meetingDuration: duration,
        company: company?.id?.value,
        lead: lead?.id?.value,
        opportunity:
          formData?.opportunity?.['data']?.id?.value ||
          formData?.opportunity?.['id']?.value ||
          opportunity?.id?.value,
        calendarId: calendarsAvailable?.data?.find(c => c.primary)?.id,
        accountId: calendarsAvailable?.data?.find(c => c.primary)?.accountId,
        invitees: invitees.map(i => i.email),
        inviteesDetails: invitees,
        otherFields: stringifyArraysInObjects(rest),
        reminderTemplateId: reminderTemplate,
        conferencingGoogleMeet,
        reminderTimeInMinutes: reminderBefore * reminderBeforeMultiplicator,
        skipCalendarEventCreation:
          connections?.list?.length === 0 ||
          !calendarsAvailable ||
          skipCalendarCreation ||
          !openCalendarPopupAfterMeeting,
        calendarNotes: serializeNoteText,
        internalNotes: serializeInternalNoteText,
      };
      api
        .post('/messaging/calendar/event', data)
        .then(() => {
          createToast({
            type: 'success',
            message: t('toasts.success'),
          });
          mixpanel.track(MIXPANEL_EVENTS.CLICK_ON_CREATE_FROM_CALENDAR_MODAL);
          handleClose();
          setIsSubmitting(false);
          onSave?.();
        })
        .catch(() => {
          createToast({
            type: 'error',
            message: t('toasts.somethingHappenedWhileCreating'),
          });
          setIsSubmitting(false);
          onSave?.();
        });
    }
  };

  const isoDate = spacetime(date).format('iso-short') as TDateISODate;
  const leadProspectingStageId = dataModel
    ?.findFieldByLogicRole(LEAD_FIELDS_LOGIC_ROLE.STAGE)
    ?.values.find(stage => stage.name === 'Prospecting')?.id;
  const isLeadProspectingStage =
    !formData?.lead?.stage ||
    formData?.lead?.stage === LEAD_STAGE_LOGIC_ROLE.PROSPECT ||
    formData?.lead.stage === leadProspectingStageId;
  const companyProspectingStageId = dataModel
    ?.findFieldByLogicRole(COMPANY_FIELDS_LOGIC_ROLE.STAGE)
    ?.values.find(stage => stage.name === 'Prospecting')?.id;
  const isProspectingStage =
    isLeadProspectingStage ||
    !formData?.company?.stage ||
    formData?.company?.stage === COMPANY_STAGE_LOGIC_ROLE.PROSPECT ||
    formData?.company?.stage === companyProspectingStageId;
  const { openDeleteModal } = useConfirmDeleteModal();
  const handleDelete = () => {
    openDeleteModal(
      bobject,
      false,
      () => {},
      () => {
        createToast({ message: t('toasts.deleteSuccess'), type: 'success' });
        handleClose();
        onSave?.();
        onClose?.();
      },
    );
  };

  useEffect(() => {
    removeScrollOfBox();
    return recoverScrollOfBox;
  }, []);

  const { field: notesField } = useController({
    control,
    name: 'calendarNotes',
  });

  const { field: internalNotesField } = useController({
    control,
    name: 'internalNotes',
  });

  return (
    <Modal open={true} onClose={handleClose} width="90vw" className={styles.modal__container}>
      <FormProvider {...methods}>
        <MeetingModalHeader minimize={minimize} bobject={bobject} handleClose={handleClose} />
        <div className={styles._body}>
          <div className={styles._form_column} ref={ref}>
            <MainInfoForm
              prospectingStage={isProspectingStage}
              accountId={accountId}
              isEditionModal={isEditionModal}
            />

            <div className={styles['standard-form-distribution']}>
              <MeetingModalGuests users={users} />
              <MeetingModalInvitees bobject={bobject} formData={formData} users={users} />
            </div>
            <div>
              <NotesForm
                notesField={notesField}
                title={t('noteCalendar.title')}
                placeholder={t('noteCalendar.placeholder')}
              />
              <NotesForm
                notesField={internalNotesField}
                title={t('noteInternal.title')}
                placeholder={t('noteInternal.placeholder')}
              />
            </div>
            <ActivityDetailsForm
              isEditionModal={isEditionModal}
              formData={{ company, lead }}
              accountId={accountId}
            />
          </div>
          <Calendar
            limitHeight={false}
            day={isoDate}
            mode="week"
            events={eventsPerDay}
            notConnected={eventsTypeSelected === 'nylas' && connections?.list?.length === 0}
            onCalendarReconnect={() => {
              mutateConnections().then(() => {
                mutateCalendars();
              });
            }}
            selectedTimezone={selectedTimezone}
          />
        </div>
        <ModalFooter className={styles.footer}>
          <div>
            <Button variant="tertiary" onClick={handleClose}>
              {t('cancel')}
            </Button>
            {isEditionModal && (
              <Button variant="tertiary" color="tomato" onClick={handleDelete}>
                {t('delete')}
              </Button>
            )}
          </div>
          <div className={styles._footer_buttons_right}>
            {connections?.list?.length > 0 && !isEditionModal && (
              <Tooltip
                title={createInCalendarCheckboxDisabled && t('notAllowedTitle')}
                position="top"
              >
                <Checkbox
                  size="small"
                  checked={
                    createAlwaysOnLinkedCalendar
                      ? createAlwaysOnLinkedCalendar
                      : !skipCalendarCreation
                  }
                  disabled={createInCalendarCheckboxDisabled}
                  onClick={v => setSkipCalendarCreation(!v)}
                >
                  {t('createEventInCalendar')}
                </Checkbox>
              </Tooltip>
            )}
            <Button
              disabled={isSubmitting || !canSave}
              variant="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {isSubmitting ? (
                <Spinner size={16} color="bloobirds" name="loadingCircle" />
              ) : (
                `${isEditionModal ? t('save') : t('create')}`
              )}
            </Button>
          </div>
        </ModalFooter>
      </FormProvider>
    </Modal>
  );
}

export const MeetingModal = (props: IMeetingModalContext) => {
  const initialContext = {
    id: props?.id,
    accountId: props?.accountId,
    userId: props?.userId,
    settings: props?.settings,
    connections: props?.connections,
    mutateConnections: props?.mutateConnections,
    dataModel: props?.dataModel,
  };
  return (
    <MeetingsModalContext.Provider value={initialContext}>
      <MeetingModalContent />
    </MeetingsModalContext.Provider>
  );
};
