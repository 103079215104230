import React, { useState } from 'react';

import { Button, Label, Tab, TabGroup, Text } from '@bloobirds-it/flamingo-ui';
import spacetime from 'spacetime';

import { api } from '../../utils/api';
import { ActivityTab } from './activityTab';
import { usePlayground } from './playgroundPages';
import styles from './playgroundPages.module.css';
import { PromptTab } from './promptTab';
import { ResultTab } from './resultTab';

export function TestResults() {
  const { selectedRun, selectedTestSet, mutateTestRuns, selectedInsight } = usePlayground();
  const [selectedTab, setSelectedTab] = useState('prompt');

  async function handleStartTest() {
    try {
      await api.post(`/copilot/playground/test-run/${selectedRun.pk}/start`);
      mutateTestRuns();
    } catch (error) {
      console.log('error', error);
    }
  }

  return (
    <div className={styles.testRun_container}>
      {selectedRun ? (
        <>
          <div className={styles.test_results_header}>
            <div className={styles.test_header}>
              <div className={styles.test_results_header_title}>
                <Text size="l" color="peanut" weight="bold">
                  Run {spacetime(selectedRun?.created_at).format('nice')}
                </Text>
                <Label size="small" color="purple">
                  {selectedRun?.status}
                </Label>
              </div>
              <div>
                {selectedRun?.status === 'NOT_STARTED' && (
                  <Button
                    size="small"
                    color="peanut"
                    variant="secondary"
                    iconLeft="play"
                    onClick={handleStartTest}
                  >
                    Start test
                  </Button>
                )}
              </div>
            </div>
            <div className={styles.test_results_header_labels}>
              <div className={styles.test_results_header_label}>
                <Text size="xs" weight="bold">
                  Insight:
                </Text>
                <Text size="xs" color="softPeanut">
                  {selectedInsight?.title}
                </Text>
              </div>
              <div className={styles.test_results_header_label}>
                <Text size="xs" weight="bold">
                  Version:
                </Text>
                <Text size="xs" color="softPeanut">
                  {selectedInsight?.version}
                </Text>
              </div>
              <div className={styles.test_results_header_label}>
                <Text size="xs" weight="bold">
                  Activity type:
                </Text>
                <Label size="small">{selectedTestSet?.activity_type}</Label>
              </div>
              <div className={styles.test_results_header_label}>
                <Text size="xs" weight="bold">
                  Test set:
                </Text>
                <Text size="xs" color="softPeanut">
                  {selectedTestSet?.name}
                </Text>
              </div>
            </div>
            <div className={styles.test_results_header_labels}>
              <div className={styles.test_results_header_label}>
                <Text size="xs" weight="bold">
                  Insight id:
                </Text>
                <Text size="xs" color="softPeanut">
                  {selectedInsight?.id}
                </Text>
              </div>
              <div className={styles.test_results_header_label}>
                <Text size="xs" weight="bold">
                  Version id:
                </Text>
                <Text size="xs" color="softPeanut">
                  {selectedInsight?.versionIdentifier}
                </Text>
              </div>
            </div>
          </div>
          <div className={styles.test_results_tab_header}>
            <TabGroup value={selectedTab} onClick={setSelectedTab}>
              <Tab color="peanut" name="Prompt" size="s" iconLeft="textEditor" iconLeftSize={12}>
                <PromptTab />
              </Tab>
              <Tab color="peanut" name="Activities" size="s" iconLeft="list" iconLeftSize={12}>
                <ActivityTab />
              </Tab>
              {/*<Tab color="peanut" name="Result" size="s" iconLeft="barchart" iconLeftSize={12}>
                <ResultTab />
              </Tab>*/}
            </TabGroup>
          </div>
        </>
      ) : (
        <div className={styles.tests_result_empty}>
          <span>👈</span>
          <Text size="l" color="peanut" weight="bold">
            Select a run to see the results and / or edit values for the selected activities
          </Text>
          <Text size="l" color="peanut" align="center">
            Or start a new run to test new prompts
          </Text>
        </div>
      )}
    </div>
  );
}
