import React, { useState } from 'react';

import { useHover } from '@bloobirds-it/flamingo-ui';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import { Environment } from '../../../../types/playbook';
import styles from '../../playbookFeed.module.css';
import { TemplateListProps, TemplatesBlock } from '../../playbookFeed.types';
import { usePlaybookFeed } from '../../usePlaybookFeed';
import { TemplatesListHeader, TemplatesWrapper } from './components/templateListComponents';

export function TemplateListDisplay({
  messagingTemplates,
  shouldShowTooltip,
  handleAddTemplateClick,
  renderTemplate,
  paginationProps,
  templatesBlock,
  noTemplatesProps,
}: TemplateListProps) {
  const { environment } = usePlaybookFeed();
  const [folded, setFolded] = useState<boolean>(false);
  const [ref, isHovering] = useHover();

  const toggleFolded = () => setFolded(!folded);

  return (
    <div
      ref={ref}
      className={clsx(styles.listContainer, {
        [styles.dialerListContainer]: environment === Environment.DIALER,
      })}
    >
      <TemplatesListHeader
        {...{
          shouldShowTooltip,
          handleAddTemplateClick,
          templatesBlock,
          folded,
          toggleFolded,
          showFoldButton: isHovering && templatesBlock === TemplatesBlock.MyTemplates,
        }}
      />
      <AnimatePresence>
        {!folded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.25 }}
          >
            <TemplatesWrapper
              emptyStates={noTemplatesProps}
              paginationProps={paginationProps}
              templatesBlock={templatesBlock}
            >
              {messagingTemplates?.map((template, index) =>
                renderTemplate(
                  template,
                  index === messagingTemplates?.length - 1 &&
                    templatesBlock === TemplatesBlock.MyTemplates,
                ),
              )}
            </TemplatesWrapper>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
